import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormTabInfo from "./Forms/FormTabInfo";
import api from "../../utils/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { academicYearSchema } from "./academicYearSchema";
import { ACADEMIC_YEAR_URL } from "../../utils/endpoints";
import FormTabInfoResume from "./Forms/FormTabInfoResume";
import SpecificationYear from "../../helpers/specificationYear";
import { formStyles } from "../../utils/styles";
import { REQUIRED_FIELD } from "../../utils/constants";

export default function FormAcademicYear({ isDisabled, setIsDisabled }) {
  const navigate = useNavigate();
  const [regexFormatYear, setRegexFormatYear] = useState([]);
  const [fieldDisabled, setFieldDisabled] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(academicYearSchema),
  });

  const { addToast } = Toast();
  const year = watch("year");
  const yearFormat = watch("yearFormat");

  const onSubmit = async (data) => {
    try {
      const res = await api.post(ACADEMIC_YEAR_URL, {
        ...data,
      });
      if (res.status === 200) {
        navigate("/anos-lectivos");
        addToast({
          title: "Ano Lectivo cadastrado com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  useEffect(() => {
    if (yearFormat) {
      setFieldDisabled(false);
    } else {
      setFieldDisabled(true);
    }
  }, [yearFormat]);

  const handleSetToResumePageOrShowErrors = (e) => {
    e.preventDefault();
    if (!year) {
      return addToast({ title: REQUIRED_FIELD, status: "error" });
    }
    let validateYear = year?.replaceAll("_", "")?.split("/");
    if (
      !SpecificationYear.isSatisfiedBy({ year, regexFormatYear, validateYear })
    ) {
      return addToast({
        title: "Ano Lectivo inválido",
        status: "error",
      });
    }
    setIsDisabled(true);
  };

  return (
    <>
      <Heading mb={4}>Abrir Novo Ano Lectivo</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <Box sx={formStyles}>
            {isDisabled ? (
              <FormTabInfoResume
                errors={errors}
                control={control}
                year={year}
              />
            ) : (
              <FormTabInfo
                errors={errors}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                setRegexFormatYear={setRegexFormatYear}
                fieldDisabled={fieldDisabled}
              />
            )}
          </Box>
          <Box>
            <Box>
              {isDisabled ? (
                <>
                  <Button type="submit" mr={3}>
                    Confirmar
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setIsDisabled(false);
                    }}
                  >
                    Voltar a editar
                  </Button>
                </>
              ) : (
                <Button onClick={handleSetToResumePageOrShowErrors}>
                  Abrir Novo Ano Lectivo
                </Button>
              )}
            </Box>
          </Box>
        </Stack>
      </form>
    </>
  );
}

import { useState, useMemo, useEffect, useContext } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Flex,
  Heading,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { MdCheckCircle, MdCancel, MdArchive } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Table from "../../../components/Table";
import { getTeacher } from "../../../services/getTeacher";
import {
  ACADEMIC_YEAR_URL,
  DOWNLOAD_TEACHERS,
  INVALIDATE_TEACHER,
  VALIDATE_TEACHER,
} from "../../../utils/endpoints";
import StateRender from "../../../components/StateRender";
import handleFilterOrSearch from "../../../helpers/handleFilterOrSearch";
import { Toast } from "../../../components/Toast";
import { AuthContext } from "../../../provider/AuthProvider";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import {
  ARCHIVED,
  NO_OPTIONS_MESSAGE,
  PAGE_SIZE,
} from "../../../utils/constants";
import { EditIcon } from "@chakra-ui/icons";
import api from "../../../utils/api";
import Select from "react-select";
import { selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { getPedagogicalTraining } from "../../../services/getPedagogicalTraining";

export default function TableTeacher({
  setDataTable,
  dataTable,
  params,
  isSchool,
  isFilter = false,
  setValue,
}) {
  const [dataTableIn, setDataTableIn] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [report, setReport] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [loadingInvalidate, setLoadingInvalidate] = useState(false);
  const [academicYear, setAcademicYear] = useState();
  const [pedagogicalTraining, setPedagogicalTraining] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [pedagogicalTrainingOptions, setPedagogicalTrainingOptions] = useState(
    []
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const { auth } = useContext(AuthContext);

  const user = auth.user;

  const { addToast } = Toast();
  const [selectedRows, setSelectedRows] = useState([]);

  const { id } = useParams();

  async function getTeacherwithParamens() {
    try {
      const data = await handleFilterOrSearch(
        {
          SchoolId: id,
          PageSize: PAGE_SIZE,
          ProvinceId: user?.provinceId ? user?.provinceId : undefined,
          CountyId: user?.countyId? user?.countyId : undefined,
        },
        addToast,
        getTeacher,
        false
      );
      setDataTableIn(data);

      setIsLoaded(true);
    } catch (err) {
      console.log("Erro", err);
    }
  }

  useEffect(() => {
    if (!isFilter) {
      setDataTableIn(dataTable);

      getTeacherwithParamens();
    } else {
      setDataTableIn(dataTable);
    }
  }, [dataTable]);

  let columns = useMemo(
    () => [
      {
        Header: "Seleccionar todos",
        disableSortBy: true,
        accessor: "checkbox",
        Cell: (item) => {
          return (
            <Checkbox
              isChecked={selectedRows.includes(item.row.original.id)}
              onChange={() => toggleRowSelection(item.row.original.id)}
            />
          );
        },
      },
      {
        Header: "Estado",
        Cell: (item) => {
          return item.row.original.isActive ? (
            <StateRender isValidated={item.row.original.isValidated} />
          ) : (
            <Flex gap={1}>
              <MdArchive size="20px" />
              {ARCHIVED}
            </Flex>
          );
        },
      },
      {
        Header: "Nº de Agente",
        accessor: "agentNumber",
      },
      {
        Header: "Nome do Docente",
        accessor: "name",
        Cell: (item) => {
          return (
            <Link
              to={
                isSchool
                  ? `/escolas/gerir/${id}/docentes/${item.data[item.row.id].id}`
                  : `/docentes/${item.data[item.row.id].id}`
              }
            >
              {item.row.original.name}
            </Link>
          );
        },
      },
      {
        Header: "Hablitação Académica",
        accessor: "academicQualification.name",
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link
              to={
                isSchool
                  ? `/escolas/gerir/${id}/docentes/${item.data[item.row.id].id}`
                  : `/docentes/${item.data[item.row.id].id}`
              }
            >
              <EditIcon />
            </Link>
          );
        },
      },
    ],
    [selectedRows]
  );

  const toggleRowSelection = (rowId) => {
    const updatedSelection = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelection);
  };

  const handleValidateOrInvalidate = async (url) => {
    selectedRows.forEach((el) => {
      api.patch(url, {
        id: el,
      });
    });
    if ( url === VALIDATE_TEACHER) {
      setLoadingValidate(true);
    }
    else {
      setLoadingInvalidate(true);
    }
    try {
      const responseAfterUpdate = await getTeacher();
      setDataTable(responseAfterUpdate);
      setValue("school", undefined);
      setValue("academicQualification", undefined);
      setValue("trainingArea", undefined);
      setValue("contractualBond", undefined);
      setValue("pedagogicalTraining", undefined);
      setValue("course", undefined);
      setValue("subject", undefined);
      setValue("state", undefined);
      setSelectedRows([]);
      addToast({
        status: "success",
        title: `Docente ${
          url.includes("invalidate") ? "invalidado" : "validado"
        } com sucesso`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      if ( url === VALIDATE_TEACHER) {
        setLoadingValidate(false);
      }
      else {
        setLoadingInvalidate(false);
      }
    }
  };

  if (isSchool) {
    columns = columns.filter((column) => column.accessor !== "school");
  }

  if (!user.permissions.includes("GetTeachers")) {
    columns = columns.filter((column) => column.accessor !== "edit");
  }

  const download = () => {
    let apiUrl = "";
    if (report?.value === 1) {
      apiUrl = `${DOWNLOAD_TEACHERS}?AcademicYearId=${academicYear?.value}`;
      if (pedagogicalTraining?.value) {
        apiUrl += `&PedagogicalTraining=${pedagogicalTraining?.value}`;
      }
    } else if (report?.value === 2) {
      apiUrl = `/api/Teacher/downloadTeachersSchoolsBonds?AcademicYear=${academicYear?.value}`;
    } else if (report?.value === 3) {
      apiUrl = "/api/Teacher/downloadTeachersSchoolsCourses";
    } else if (report?.value === 4) {
      apiUrl = "/api/Teacher/downloadTeachersSchoolsSubjects";
    }
    if (user?.schoolId) {
      apiUrl += `&SchoolId=${user.schoolId}`;
    } else if (user?.countyId) {
      apiUrl += `&CountyId=${user.countyId}`;
    } else if (user?.provinceId) {
      apiUrl += `&ProvinceId=${user.provinceId}`;
    }
    setIsLoading(true);
    downloadExcelFile(apiUrl, `${report?.label}.xlsx`);
    setIsLoading(false);
    onClose();
  };

  const options = [
    {
      value: 1,
      label: "Lista de docentes cadastrados por ano lectivo",
    },
    {
      value: 2,
      label: "Lista de docentes por escola, vínculo e ano lectivo",
    },
    {
      value: 3,
      label: "Lista de docentes por escola, cursos e anos lectivos",
    },
    {
      value: 4,
      label: "Lista de docentes por escola, disciplinas e anos lectivos",
    },
  ];

  const onChange = (e) => {
    setReport(e);
    if (e?.value) {
      onOpen();
    }
  };

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getPedagogicalTrainingOptions() {
    getPedagogicalTraining()
      .then((res) => {
        setPedagogicalTrainingOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    setAcademicYear();
    setPedagogicalTraining();
    setIsDisabled(true);
    if (report?.value === 1) {
      getAcademicYears();
      getPedagogicalTrainingOptions();
    }
    else if (report?.value === 2) {
      getAcademicYears();
    } else {
      setIsDisabled(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (academicYear?.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [academicYear]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end" mb="10px">
        <Heading as="h2" size="md" textTransform="uppercase">
          Lista de docentes
        </Heading>
        <Flex>
          <Box minW="300px">
            <Select
              placeholder="Descarregar lista"
              options={options}
              isSearchable={false}
              isClearable={true}
              onChange={onChange}
              styles={selectStyles}
            />
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Descarregar lista</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={5}>
                    <Text>{report?.label}</Text>
                    {report?.value === 1 && (
                      <>
                        <Box>
                          <Label title="Ano Lectivo" isRequired />
                          <Select
                            placeholder="Seleccionar"
                            options={academicYearOptions}
                            onChange={(value) => {
                              setAcademicYear(value);
                            }}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                        <Box>
                          <Label title="Formação pedagógica" />
                          <Select
                            placeholder="Seleccionar"
                            options={pedagogicalTrainingOptions}
                            onChange={(value) => {
                              setPedagogicalTraining(value);
                            }}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                      </>
                    )}
                    {report?.value === 2 && (
                      <Box>
                        <Label title="Ano Lectivo" isRequired />
                        <Select
                          placeholder="Seleccionar"
                          options={academicYearOptions}
                          onChange={(value) => {
                            setAcademicYear(value);
                          }}
                          noOptionsMessage={NO_OPTIONS_MESSAGE}
                        />
                      </Box>
                    )}
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    onClick={download}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                  >
                    Descarregar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        data={dataTableIn}
        isLoaded={isLoaded}
        setData={setDataTableIn}
        getFunction={getTeacher}
        setIsLoaded={setIsLoaded}
        entity="teacher"
        params={params}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      {user.permissions.includes("ValidateTeachers") && (
        <HStack mt="5">
          <Button
            rightIcon={<MdCheckCircle />}
            onClick={() => {
              handleValidateOrInvalidate(VALIDATE_TEACHER);
            }}
            isLoading={loadingValidate}
            isDisabled={loadingValidate}
          >
            Validar Seleccionados
          </Button>

          <Button
            variant="danger"
            rightIcon={<MdCancel />}
            onClick={() => {
              handleValidateOrInvalidate(INVALIDATE_TEACHER);
            }}
            isLoading={loadingInvalidate}
            isDisabled={loadingInvalidate}
          >
            Não Validar Seleccionados
          </Button>
        </HStack>
      )}
    </>
  );
}

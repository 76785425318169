import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import Label from "../../../components/Label";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";

function DownloadReport({ item, apiUrl, academicYearOptions }) {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    setIsDisabled(true);
    onClose();
  };

  return (
    <>
      <Button variant="secondary" isDisabled={loading} onClick={onOpen}>
        {loading ? <Spinner size="sm" /> : "Descarregar"}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="lg"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Descarregar quadro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <Text>{item.row.original.name}</Text>
              <Box>
                <Label title="Ano Lectivo" isRequired />
                <Select
                  placeholder="Seleccionar"
                  options={academicYearOptions}
                  onChange={(value) => {
                    setYear(value);
                    setIsDisabled(false);
                  }}
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" mr={3} onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                apiUrl += `${item.row.original.id}&AcademicYearId=${year.value}`;
                downloadExcelFile(
                  apiUrl,
                  `Quadro ${item.row.original.name}.xlsx`,
                  setLoading
                );
                handleClose();
              }}
              isDisabled={isDisabled}
            >
              Descarregar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DownloadReport;

import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getIdentificationDocumentType } from "../../../services/getIdentificationDocumentType";
import { Toast } from "../../../components/Toast";
import Label from "../../../components/Label";
import Select from "react-select";
import { selectStyles } from "../../../utils/styles";
import {
  NO_OPTIONS_MESSAGE,
  regexPattern,
  REQUIRED_FIELD,
  TYPEDOCUMENTBI,
} from "../../../utils/constants";
import api from "../../../utils/api";
import { STUDENT_URL, TEACHER_URL } from "../../../utils/endpoints";
import { useParams } from "react-router-dom";

function EditDocument({
  documentType,
  documentNumber,
  setValue,
  entity,
  isDisabled,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditable, setIsEditable] = useState(false);
  const [
    identificationDocumentTypeOptions,
    setIdentificationDocumentTypeOptions,
  ] = useState([]);
  const [newDocumentNumber, setNewDocumentNumber] = useState(documentNumber);
  const [newDocumentType, setNewDocumentType] = useState(null);
  const { addToast } = Toast();
  const { id } = useParams();

  const getIdentificationDocumentTypeOptions = async () => {
    getIdentificationDocumentType()
      .then((res) => {
        setIdentificationDocumentTypeOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  };

  useEffect(() => {
    if (isOpen) {
      getIdentificationDocumentTypeOptions();
      setNewDocumentNumber(documentNumber);
    }
  }, [isOpen]);

  useEffect(() => {
    if (identificationDocumentTypeOptions.length > 0) {
      setNewDocumentType(
        identificationDocumentTypeOptions.filter(
          (option) => option.value == documentType
        )[0]
      );
    }
  }, [identificationDocumentTypeOptions]);

  const handleClose = () => {
    setIsEditable(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!newDocumentType || !newDocumentNumber) {
      return addToast({ title: REQUIRED_FIELD, status: "error" });
    }
    if (newDocumentType?.value === 1 && !regexPattern.exec(newDocumentNumber)) {
      return addToast({
        title: "Formato do nº de documento de identificação inválido",
        status: "error",
      });
    }
    const API_URL = entity === "aluno" ? STUDENT_URL : TEACHER_URL;
    const response = await api.get(
      `${API_URL}?DocumentNumber=${newDocumentNumber}&TypeDocumentId=${newDocumentType?.value}`
    );
    if (response.data?.result.length > 0) {
      if (
        response.data?.result[0].id != id &&
        response.data?.result[0].documentNumber === newDocumentNumber
      ) {
        return addToast({
          title:
            "O n.º do documento de identificação que inseriu já se encontra cadastrado",
          status: "error",
        });
      } else {
        addToast({
          title: "O n.º do documento de identificação foi alterado com sucesso",
          status: "success",
        });
        setValue("typeDocument", newDocumentType.value.toString());
        setValue("typeDocumentName", newDocumentType.label);
        setValue("documentNumber", newDocumentNumber);
        onClose();
      }
    } else {
      addToast({
        title: "O n.º do documento de identificação foi alterado com sucesso",
        status: "success",
      });
      setValue("typeDocument", newDocumentType.value.toString());
      setValue("typeDocumentName", newDocumentType.label);
      setValue("documentNumber", newDocumentNumber);
      onClose();
    }
  };

  return (
    <>
      <IconButton
        variant="secondary"
        isDisabled={isDisabled}
        icon={<EditIcon />}
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Documento de Identificação</ModalHeader>
          <ModalCloseButton />
          {isEditable ? (
            <>
              <ModalBody>
                <Stack gap={5}>
                  <FormControl>
                    <Label
                      title="Tipo de Documento de Identificação"
                      isRequired={true}
                    />
                    <Select
                      placeholder="Seleccionar"
                      options={identificationDocumentTypeOptions}
                      onChange={(option) => {
                        setNewDocumentType(option);
                      }}
                      value={newDocumentType || ""}
                      styles={selectStyles}
                      isSearchable={false}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  </FormControl>
                  <FormControl>
                    <Label
                      title="Nº de Documento de Identificação"
                      isRequired={true}
                    />
                    <Input
                      value={newDocumentNumber}
                      onChange={(e) => setNewDocumentNumber(e.target.value)}
                    />
                    {newDocumentType?.label === TYPEDOCUMENTBI && (
                      <FormHelperText>Exemplo: 123456789AA123</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button variant="secondary" onClick={handleClose} mr={3}>
                  Cancelar
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Confirmar
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                Tem a certeza que pretende editar/alterar o documento de
                identificação deste {entity}?
              </ModalBody>

              <ModalFooter>
                <Button variant="secondary" onClick={handleClose} mr={3}>
                  Não
                </Button>
                <Button
                  onClick={() => {
                    setIsEditable(true);
                  }}
                >
                  Sim
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditDocument;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Label from "../../../../components/Label";
import api from "../../../../utils/api";
import {
  ADD_ITEM_LOOKUP,
  UPDATE_ITEM_LOOKUP,
} from "../../../../utils/endpoints";
import { Toast } from "../../../../components/Toast";
import { lookUps } from "../..";
import { useParams } from "react-router-dom";
import CustomFormControlSelect from "../../../../components/CustomFormControlSelect";
import { REQUIRED_FIELD } from "../../../../utils/constants";

export default function ModalAssociate({
  setIsOpen,
  isOpen,
  title,
  setDataTable,
  ParentName,
  buttonTitle,
  idItem,
  watch,
  register,
  errors,
  handleSubmit,
  control,
}) {
  const { addToast } = Toast();
  const code = watch("code");
  const name = watch("name");
  const { table } = useParams();
  const [parentData, setParentData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (lookUps[table - 1].getParent !== undefined) {
        const response = await lookUps[table - 1]?.getParent();
        setParentData(response);
      }
    };
    getData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const entity_name = lookUps[table - 1].entityName;

      const bodyRequest =
        lookUps[table - 1].getParent === undefined
          ? {
              Code: data.code,
              Name: data.name,
              EntityType: entity_name,
            }
          : {
              Code: data.code,
              Name: data.name,
              EntityType: entity_name,
              ParentId: watch("parent").value,
            };

      const response =
        buttonTitle === "Inserir"
          ? await api.post(ADD_ITEM_LOOKUP, bodyRequest)
          : await api.patch(UPDATE_ITEM_LOOKUP, { ...bodyRequest, id: idItem });

      addToast({
        status: "success",
        title: "Item adicionado com sucesso",
      });

      const responseTable = await lookUps[table - 1].getFunction({
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(responseTable);
      setIsOpen(false);
    } catch (e) {
      addToast({
        status: "error",
        title: "Ocorreu um erro ao adicionar item",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={5}>
              <Box>
                <FormControl isInvalid={!!errors.code}>
                  <Label title="Código" isRequired />
                  <Input value={code} {...register("code")} size="md" />
                  <FormErrorMessage>
                    {errors.code && <>{REQUIRED_FIELD}</>}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl isInvalid={!!errors.name}>
                  <Label title="Nome" isRequired />
                  <Input value={name} {...register("name")} size="md" />
                  <FormErrorMessage>
                    {errors.name && <>{REQUIRED_FIELD}</>}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              {lookUps[table - 1].getParent !== undefined && (
                <Box>
                  <CustomFormControlSelect
                    errors={errors}
                    fieldNameObject="parent"
                    isDisabled={false}
                    parent={{ value: 1 }}
                    isClearable={false}
                    options={parentData}
                    fieldName={ParentName}
                    control={control}
                    isRequired={true}
                  />
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
              mr={3}
            >
              Cancelar
            </Button>
            <Button type="submit" isLoading={loading} isDisabled={loading}>
              {buttonTitle}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

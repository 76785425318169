import { useContext, useState } from "react";
import {
  Flex,
  Box,
  Heading,
  Stack,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  useDisclosure,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { backgroundColor } from "../../utils/colors";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { REQUIRED_FIELD } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import api from "../../utils/api";
import { GET_SELF_DATA_URL } from "../../utils/endpoints";
import ResetPassword from "./ResetPassword";
import Label from "../../components/Label";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const schema = z.object({
    email: z.string().nonempty(REQUIRED_FIELD).email("Email inválido"),
    password: z.string().nonempty(REQUIRED_FIELD),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const handleLogin = async () => {
    const data = getValues();
    const user = await login(data);
    if (user) {
      api.get(`${GET_SELF_DATA_URL}?Id=${user.id}`).then((response) => {
        const schoolId = response.data.userSchool?.id;
        if (schoolId) {
          navigate(`/gerir/${schoolId}`);
        } else {
          navigate("/inicio");
        }
      });
    }
  };

  return (
    <Flex as="main" width="100vw" height="100vh">
      <Flex
        width="40%"
        align="center"
        justifyContent="center"
        bg={backgroundColor}
        direction="column"
        paddingBottom="40px"
      >
        <Box width="75%" marginTop="100px">
          <Heading as="h1" mb={20}>
            <Image
              src="/images/sietp.svg"
              alt="Sistema de Informação para o Ensino Técnico-Profissional"
            />
          </Heading>
          <Box as="form" onSubmit={handleSubmit(handleLogin)}>
            <Heading as="h2" size="lg" mb={5}>
              Entrada
            </Heading>
            <Stack spacing={5}>
              <FormControl isInvalid={!!errors.email}>
                <Label title="Email do utilizador" />
                <Input type="email" {...register("email")} />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <Label title="Senha" />
                <InputGroup>
                  <Input
                    pr="5.5rem"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <InputRightElement width="5.25rem" pe="0.25rem">
                    <Button
                      variant="link"
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                    >
                      {showPassword ? "Esconder" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Button type="submit">Entrar</Button>
            </Stack>
          </Box>
          <Flex justifyContent="end" mt={5}>
            <Button variant="link" onClick={onOpen}>
              Esqueci-me da senha
            </Button>
            <ResetPassword isOpen={isOpen} onClose={onClose} />
          </Flex>
        </Box>
        <Box width="75%" marginTop="auto">
          <Text fontSize="14px" fontWeight="600">
            <Link href="mailto:suporte@example.com" fontSize="md">
              Contactar suporte
            </Link>{" "}
            <Box as="span" fontWeight="400">
              (suporte@example.com)
            </Box>
          </Text>
        </Box>
      </Flex>
      <Flex
        width="60%"
        bgImage="/images/login.png"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        direction="column"
        paddingStart="60px"
        paddingEnd="60px"
        paddingBottom="40px"
        justifyContent="center"
      >
        <Flex direction="column" marginTop="350px">
          {/* <Text as="b" fontSize="2xl" color="#fff" textTransform="uppercase">
            Descubra, explore, realize:
          </Text>
          <Text fontSize="2xl" color="#fff">
            Sistema de Informação para o Ensino Técnico-Profissional
          </Text> */}
        </Flex>
        <Flex marginTop="auto" justifyContent="space-between">
          <Text fontSize="14px" color="#fff">
            © 2023 Sistema de Informação para o Ensino Técnico-Profissional -
            Todos os direitos reservados
          </Text>
          <Text fontSize="14px" color="#fff">
            V.0.10
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Login;

import {
  Box,
  Button,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  HStack,
  Tab,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormEditTabInfo from "./FormsEdit/FormTabInfo";
import FormTabSubject from "./Forms/FormTabSubject";
import api from "../../utils/api";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { courseSchema } from "./courseSchema";
import { ZodError } from "zod";
import {
  COURSE_URL,
  ENABLE_COURSE_URL,
  LINK_COURSE_SUBJECT,
  UNLINK_COURSE_SUBJECT,
} from "../../utils/endpoints";
import { generateCode } from "../../utils/generateCode";
import FormTabInfoResume from "./Forms/FormTabInfoResume";
import { getCourseById } from "../../services/getCourseById";
import { DISABLE_COURSE_URL } from "../../utils/endpoints";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Alert from "../../components/Alert";

import { AuthContext } from "../../provider/AuthProvider";
import { CrumbContext } from "../../provider/CrumbProvider";
import { formStyles } from "../../utils/styles";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../utils/constants";

export default function EditCourse() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedOptionsSubject, setSelectedOptionsSubject] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [isOpenDearchive, setIsOpenDearchive] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [actualRow, setActualRow] = useState({});
  const [optionsSubject, setOptionsSubject] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [needsInitialValue, setNeedsInitialValue] = useState(true);
  const [isActive, setIsActive] = useState();

  const [initialsSubjects, setInitialSubjects] = useState([]);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: zodResolver(courseSchema),
  });
  const { addToast } = Toast();
  const name = watch("name");
  const educationLevel = watch("educationLevel");
  const subsystemEducation = watch("educationSubsystem");
  const generalArea = watch("generalArea");
  const specificArea = watch("specificArea");
  const detailedArea = watch("detailedArea");
  const domainPNFQ = watch("domainPNFQ");
  const technicalEducation = watch("technicalEducation");
  const cicleEducationRead = watch("cicleEducation");
  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { crumb, setCrumb } = useContext(CrumbContext);
  const [isEditable, setIsEditable] = useState(true);

  const handleDesassociate = async () => {
    const item = actualRow.data[actualRow.row.index].code;
    const dataFiltered = [
      ...dataTable.filter((e) => {
        if (e.code !== item) {
          return { code: e.code, label: e.label, value: e.value };
        } else {
          setOptionsSubject([
            ...optionsSubject.filter((j) => j.code !== e.code),
            { code: e.code, label: e.label, value: e.value },
          ]);
        }
      }),
    ];
    setDataTable(dataFiltered);
    setSelectedOptionsSubject(dataFiltered);
    try {
      const res = await api.patch(UNLINK_COURSE_SUBJECT, {
        id: id,
        subjects: [
          actualRow.data[actualRow.row.index].id ??
            actualRow.data[actualRow.row.index].value,
        ],
      });
      if (res.status === 200) {
        addToast({
          title: "Disciplina dessasociada com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
    setIsOpen(false);
    setIsOpenAlert(false);
  };

  const handleValidateAndChangeTab = () => {
    try {
      courseSchema.parse(getValues());
      clearErrors();
      handleTabChange(activeTab + 1);
    } catch (e) {
      console.log(e);
      clearErrors();
      if (e instanceof ZodError) {
        console.log(e);
        e.issues.forEach((errors) => {
          setError(errors.path[0], {
            type: "manual",
            message: errors.message,
          });
        });
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getCourseById(id);
      setIsActive(response.isActive);
      setCrumb({
        ...crumb,
        course: response.name,
      });
      setValue("name", response.name);
      setValue("className", response.className);
      setValue("educationLevel.label", response.educationLevel.name);
      setValue("educationLevel.value", response.educationLevel.id);
      setValue("educationSubsystem.label", response.subsystemEducation.name);
      setValue("educationSubsystem.value", response.subsystemEducation.id);
      setValue("generalArea.label", response.generalArea?.name);
      setValue("generalArea.value", response.generalArea?.id);
      setValue("generalArea.code", response.generalArea?.code);
      setValue("specificArea.label", response.specificArea?.name);
      setValue("specificArea.code", response.specificArea?.code);
      setValue("specificArea.value", response.specificArea?.id);
      setValue("detailedArea.label", response.detailedArea?.name);
      setValue("detailedArea.value", response.detailedArea?.id);
      setValue("detailedArea.code", response.detailedArea?.code);
      setValue("domainPNFQ.label", response.domainPNFQ?.name);
      setValue("domainPNFQ.value", response.domainPNFQ?.id);
      setValue("domainPNFQ.code", response.domainPNFQ?.code);
      setValue("technicalEducation.label", response.technicalEducation?.name);
      setValue("technicalEducation.value", response.technicalEducation?.id);
      setValue("technicalEducation.code", response.technicalEducation?.code);
      setValue("cicleEducation.label", response.educationCicle?.name);
      setValue("cicleEducation.value", response.educationCicle?.id);
      setIsValid(response.isValidated);
      const responseDTO = response.subjects.map((e) => ({
        code: e.code,
        id: e.id,
        label: e.name,
      }));
      setSelectedOptionsSubject(responseDTO);
      setDataTable(responseDTO);
      setInitialSubjects(responseDTO);
      setIsEditable(response.isEditable);
    };

    getData();
  }, []);

  const handleDeleteCourse = async () => {
    try {
      await api.delete(`${COURSE_URL}/${id}`);
      navigate("/cursos");
      addToast({
        title: "Curso excluído com sucesso",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const handleArchiveCourse = async () => {
    try {
      await api.patch(DISABLE_COURSE_URL, {
        id,
      });
      navigate("/cursos");
      addToast({
        title: "Curso arquivado com sucesso",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const handleDesarchiveCourse = async () => {
    try {
      await api.patch(ENABLE_COURSE_URL, {
        id,
      });
      navigate("/cursos");
      addToast({
        title: "Curso desarquivado com sucesso",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const onSubmit = async (data) => {
    if (dataTable.length <= 0) {
      addToast({
        title: "Obrigatório associar disciplinas",
        status: "error",
      });
      throw new ZodError("Lista de disciplinas vazia");
    }

    try {
      const code = generateCode(1, 900);

      const res = await api.patch(`${COURSE_URL}/editCourse`, {
        name: data.name,
        className: data.className,
        code,
        designacao: data.name,
        educationalLevel: data.educationLevel.value,
        educationalSubsystem: data.educationSubsystem.value,
        educationalCycle: data.cicleEducation?.value,
        generalArea: data.generalArea.value,
        specificArea: data.specificArea.value,
        detailedArea: data.detailedArea.value,
        courseTechnical: data.technicalEducation?.value,
        pnfqDomain: data.domainPNFQ?.value,
        id,
      });

      if (res.status === 200) {
        const subjectsIdSelectedToLink = dataTable.map((e) => e.value || e.id);

        if (subjectsIdSelectedToLink.length > 0) {
          await api.patch(LINK_COURSE_SUBJECT, {
            id: res.data.id,
            subjects: subjectsIdSelectedToLink,
          });
        }

        navigate("/cursos");
        addToast({
          title: "Curso actualizado com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      console.log(e);
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const [isToBeEdited, setIsToBeEdited] = useState(true);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isToBeEdited ? (
        <>
          <Box mb={5}>
            <Heading mb="4">Resumo | {name}</Heading>
          </Box>

          <Box sx={formStyles} mb={5}>
            <FormTabInfoResume
              errors={errors}
              control={control}
              watch={watch}
              dataTable={dataTable}
            />
          </Box>
        </>
      ) : (
        <>
          <Heading mb="5">Editar {name}</Heading>
          <Tabs mt="6" index={activeTab} onChange={handleTabChange}>
            <TabList>
              <Tab>Informações do Curso</Tab>
              <Tab>Disciplinas</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pt="20px">
                <Heading as="h2" fontSize="md" textTransform="uppercase" pb={2}>
                  Informações do Curso
                </Heading>

                <Box sx={formStyles} mb={5}>
                  <FormEditTabInfo
                    educationLevel={educationLevel}
                    subsystemEducation={subsystemEducation}
                    errors={errors}
                    register={register}
                    control={control}
                    isToBeEdited={isToBeEdited}
                    generalArea={generalArea}
                    specificArea={specificArea}
                    detailedArea={detailedArea}
                    domainPNFQ={domainPNFQ}
                    technicalEducation={technicalEducation}
                    cicleEducationRead={cicleEducationRead}
                    setValue={setValue}
                    backEdit={needsInitialValue}
                    setBackEdit={setNeedsInitialValue}
                  />
                </Box>
              </TabPanel>

              <TabPanel p={0} pt="20px">
                <Heading as="h2" fontSize="md" textTransform="uppercase" pb={2}>
                  Disciplinas
                </Heading>

                <FormTabSubject
                  selectedOptionsSubject={selectedOptionsSubject}
                  setSelectedOptionsSubject={setSelectedOptionsSubject}
                  dataTable={dataTable}
                  setDataTable={setDataTable}
                  handleDesassociate={handleDesassociate}
                  actualRow={actualRow}
                  setActualRow={setActualRow}
                  optionsSubject={optionsSubject}
                  setOptionsSubject={setOptionsSubject}
                  isOpenAlert={isOpenAlert}
                  setIsOpenAlert={setIsOpenAlert}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}

      {isToBeEdited ? (
        <Box>
          {user.permissions.includes("EditCourses") && isEditable && (
            <Button
              onClick={() => {
                setIsToBeEdited(false);
              }}
            >
              Editar Curso
            </Button>
          )}
        </Box>
      ) : (
        <>
          <Flex justifyContent="space-between">
            <Box>
              <Button type="submit" mr={3}>
                Guardar
              </Button>
              <Button variant="secondary" onClick={() => navigate("/cursos")}>
                Cancelar
              </Button>
            </Box>
            {activeTab === 0 ? (
              <Button
                variant="secondary"
                rightIcon={<MdKeyboardArrowRight />}
                onClick={handleValidateAndChangeTab}
              >
                Disciplinas
              </Button>
            ) : (
              <Button
                variant="secondary"
                leftIcon={<MdKeyboardArrowLeft />}
                onClick={() => handleTabChange(activeTab - 1)}
              >
                Informações do Curso
              </Button>
            )}
          </Flex>
          <Flex direction="row">
            {isActive ? (
              <HStack mt={4}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsOpenArchive(true);
                  }}
                >
                  Arquivar
                </Button>
                <Tooltip
                  hasArrow
                  label="Esta acção arquiva este curso no sistema"
                >
                  <Flex alignItems="center">
                    <BsQuestionCircle />
                  </Flex>
                </Tooltip>
              </HStack>
            ) : (
              <HStack mt={4}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsOpenDearchive(true);
                  }}
                >
                  Desarquivar
                </Button>
                <Tooltip
                  hasArrow
                  label="Esta acção desarquiva este curso no sistema"
                >
                  <Flex alignItems="center">
                    <BsQuestionCircle />
                  </Flex>
                </Tooltip>
              </HStack>
            )}
            <HStack ml={4} mt={4}>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Excluir
              </Button>
              <Tooltip
                hasArrow
                label="Esta acção exclui, de forma permanente, este curso do sistema"
              >
                <Flex alignItems="center">
                  <BsQuestionCircle />
                </Flex>
              </Tooltip>
            </HStack>
          </Flex>
          <Alert
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            text={DIALOG_MESSAGE}
            title="Excluir Curso"
            handleDelete={handleDeleteCourse}
            buttonText="Excluir"
          />
          <Alert
            isOpen={isOpenArchive}
            setIsOpen={setIsOpenArchive}
            text="Tem a certeza que pretende arquivar o Curso?"
            title="Arquivar Curso"
            handleDelete={handleArchiveCourse}
            buttonText="Arquivar"
          />
          <Alert
            isOpen={isOpenDearchive}
            setIsOpen={setIsOpenDearchive}
            text="Tem a certeza que pretende desarquivar o Curso?"
            title="Desarquivar Curso"
            handleDelete={handleDesarchiveCourse}
            buttonText="Desarquivar"
          />
        </>
      )}
      {/* {activeTab <= 0 ? (
            <>
              {isToBeEdited === true ? (
                ""
              ) : (
               
              )}
            </>
          ) : null}

          {!isToBeEdited ? (
            <>
              
            </>
          ) : (
            ""
          )} */}
    </form>
  );
}

import { useMemo } from "react";
import Table from "../../../components/Table";
import DownloadReport from "./DownloadReport";

function TableGeneralData({ data, academicYearOptions }) {
  let columns = useMemo(
    () => [
      {
        Header: "Indicador",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Quadros padronizados",
        disableSortBy: true,
        Cell: (item) => {
          let apiUrl = `/api/Reports/downloadTotalsReportsRequest?ReportType=`;
          return (
            <DownloadReport
              item={item}
              apiUrl={apiUrl}
              academicYearOptions={academicYearOptions}
            />
          );
        },
      },
    ],
    [academicYearOptions]
  );

  return (
    <>{data && <Table columns={columns} data={data} isPaginated={false} />}</>
  );
}

export default TableGeneralData;

import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import {
  MdCalendarMonth,
  MdHolidayVillage,
  MdMenuBook,
  MdPerson,
  MdSchool,
  MdScreenSearchDesktop,
  MdSettings,
  MdStar,
  MdTableChart,
  MdWork,
} from "react-icons/md";
import CardItem from "./CardItem";
import {
  ACADEMIC_YEAR_SUMMARY,
  AUDIT_SUMMARY,
  COURSE_SUMMARY,
  LOOKUP_SUMMARY,
  REPORTS_SUMMARY,
  ROLE_SUMMARY,
  SCHOOL_SUMMARY,
  STUDENT_SUMMARY,
  TEACHER_SUMMARY,
  USER_SUMMARY,
} from "../../utils/constants";

function Home() {
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Box>
      <Heading mb={5}>Início</Heading>
      <Heading fontSize="18px" textTransform="uppercase" mb={3}>
        Atalhos
      </Heading>
      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
        {user.permissions.includes("GetSchool") && (
          <GridItem>
            <CardItem
              title="Escolas"
              link="/escolas"
              icon={<MdHolidayVillage size={30} />}
              text={SCHOOL_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetCourses") && (
          <GridItem>
            <CardItem
              title="Cursos"
              link="/cursos"
              icon={<MdMenuBook size={30} />}
              text={COURSE_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetTeachers") && (
          <GridItem>
            <CardItem
              title="Docentes"
              link="/docentes"
              icon={<MdWork size={30} />}
              text={TEACHER_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetStudents") && (
          <GridItem>
            <CardItem
              title="Alunos"
              link="/alunos"
              icon={<MdSchool size={30} />}
              text={STUDENT_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetReports") && (
          <GridItem>
            <CardItem
              title="Estatísticas"
              link="/estatisticas"
              icon={<MdTableChart size={30} />}
              text={REPORTS_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetRoles") && (
          <GridItem>
            <CardItem
              title="Perfis"
              link="/perfis"
              icon={<MdStar size={30} />}
              text={ROLE_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetUser") && (
          <GridItem>
            <CardItem
              title="Utilizadores"
              link="/utilizadores"
              icon={<MdPerson size={30} />}
              text={USER_SUMMARY}
            />
          </GridItem>
        )}
        {(user.permissions.includes("ManageLookups") ||
          user.permissions.includes("ManageLookupsNational")) && (
          <GridItem>
            <CardItem
              title="Configurações"
              link="/configuracoes"
              icon={<MdSettings size={30} />}
              text={LOOKUP_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetAcademicYears") && (
          <GridItem>
            <CardItem
              title="Anos Lectivos"
              link="/anos-lectivos"
              icon={<MdCalendarMonth size={30} />}
              text={ACADEMIC_YEAR_SUMMARY}
            />
          </GridItem>
        )}
        {user.permissions.includes("GetAudit") && (
          <GridItem>
            <CardItem
              title="Auditoria"
              link="/auditoria"
              icon={<MdScreenSearchDesktop size={30} />}
              text={AUDIT_SUMMARY}
            />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
}

export default Home;

import { Button, Box, Heading, Grid, Stack, GridItem } from "@chakra-ui/react";

import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import api from "../../utils/api";
import { SCHOOL_URL } from "../../utils/endpoints";
import CardItem from "../Home/CardItem";
import {
  ACADEMIC_YEAR_SUMMARY,
  MY_COURSE_SUMMARY,
  MY_SCHOOL_SUMMARY,
  MY_STUDENT_SUMMARY,
  MY_TEACHER_SUMMARY,
  MY_USER_SUMMARY,
  REPORTS_SUMMARY,
} from "../../utils/constants";
import {
  MdCalendarMonth,
  MdHolidayVillage,
  MdMenuBook,
  MdPerson,
  MdSchool,
  MdTableChart,
  MdWork,
} from "react-icons/md";
import { AuthContext } from "../../provider/AuthProvider";
import { CrumbContext } from "../../provider/CrumbProvider";

export default function ManagerSchools() {
  const [name, setName] = useState("");
  const [isValidated, setIsValidated] = useState(null);

  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const { crumb, setCrumb } = useContext(CrumbContext);
  const users = user.permissions.includes("GetMySchool");

  useEffect(
    () => {
      if (auth.isSchool) {
        api.get(`/api/School/userSchool?Id=${user.id}`).then((response) => {
          setName(response.data.name);
          setIsValidated(response.data.isValidated);
          setCrumb({
            ...crumb,
            school: response.data.name,
          });
        });
      } else {
        api.get(`${SCHOOL_URL}/${id}`).then((response) => {
          const data = response.data;
          setName(data.name);
          setCrumb({
            ...crumb,
            school: data.name,
          });
        });
      }
    },
    // eslint-disable-next-line
    [id]
  );

  return (
    <Stack direction="column" spacing={8}>
      {users === true ? null : (
        <Link to="/escolas">
          <Button width="200px">Voltar para Escolas</Button>
        </Link>
      )}
      <Box>
        <Heading mb={5}>Gerir {name}</Heading>

        {auth.isSchool && !isValidated ? (
          <></>
        ) : (
          <>
            <Heading fontSize="18px" textTransform="uppercase" mb={3}>
              Atalhos da Escola
            </Heading>

            <Grid templateColumns="repeat(4, 1fr)" gap={5}>
              <GridItem>
                <CardItem
                  id={id}
                  title="Escola"
                  link={
                    auth.isSchool
                      ? `/gerir/${id}/resumo`
                      : `/escolas/gerir/${id}/resumo`
                  }
                  icon={<MdHolidayVillage size={30} />}
                  text={MY_SCHOOL_SUMMARY}
                />
              </GridItem>

              {user.permissions.includes("GetMySchool") && (
                <GridItem>
                  <CardItem
                    title="Cursos"
                    link={
                      auth.isSchool
                        ? `/gerir/${id}/cursos`
                        : `/escolas/gerir/${id}/cursos`
                    }
                    icon={<MdMenuBook size={30} />}
                    text={MY_COURSE_SUMMARY}
                  />
                </GridItem>
              )}

              {user.permissions.includes("GetTeachers") && (
                <GridItem>
                  <CardItem
                    title="Docentes"
                    link={
                      auth.isSchool
                        ? `/gerir/${id}/docentes`
                        : `/escolas/gerir/${id}/docentes`
                    }
                    icon={<MdWork size={30} />}
                    text={MY_TEACHER_SUMMARY}
                  />
                </GridItem>
              )}

              {user.permissions.includes("GetStudents") && (
                <GridItem>
                  <CardItem
                    title="Alunos"
                    link={
                      auth.isSchool
                        ? `/gerir/${id}/alunos`
                        : `/escolas/gerir/${id}/alunos`
                    }
                    icon={<MdSchool size={30} />}
                    text={MY_STUDENT_SUMMARY}
                  />
                </GridItem>
              )}

              {user.permissions.includes("GetReports") && (
                <GridItem>
                  <CardItem
                    title="Estatísticas"
                    link="/estatisticas"
                    icon={<MdTableChart size={30} />}
                    text={REPORTS_SUMMARY}
                  />
                </GridItem>
              )}

              {user.permissions.includes("GetUsersMySchool") && (
                <GridItem>
                  <CardItem
                    title="Utilizadores"
                    link={
                      auth.isSchool
                        ? `/gerir/${id}/utilizadores`
                        : `/escolas/gerir/${id}/utilizadores`
                    }
                    icon={<MdPerson size={30} />}
                    text={MY_USER_SUMMARY}
                  />
                </GridItem>
              )}

              {user.permissions.includes("GetAcademicYearsMySchool") && (
                <GridItem>
                  <CardItem
                    title="Anos Lectivos"
                    link={
                      auth.isSchool
                        ? `/gerir/${id}/anos-lectivos`
                        : `/escolas/gerir/${id}/anos-lectivos`
                    }
                    icon={<MdCalendarMonth size={30} />}
                    text={ACADEMIC_YEAR_SUMMARY}
                  />
                </GridItem>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Stack>
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdUpload } from "react-icons/md";
import {
  BOND_RESULT_URL,
  ENROLLMENT_STATUS_URL,
  STUDENT_URL,
} from "../../../utils/endpoints";
import api from "../../../utils/api";
import TableStudentResults from "./TableStudentResults";
import { Toast } from "../../../components/Toast";

function StudentResults({ school, className, classId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [students, setStudents] = useState([]);
  const [enrollmentStatusOptions, setEnrollmentStatusOptions] = useState([]);
  const [schoolBondResultOptions, setSchoolBondResultOptions] = useState([]);
  const [data, setData] = useState([]);
  const { addToast } = Toast();

  useEffect(() => {
    const getData = async () => {
      let apiUrl = `${STUDENT_URL}?ClassFullName=${className?.value}&PageSize=100`;
      if (school[0]?.value) {
        apiUrl += `&SchoolId=${school[0].value}`;
      }
      const response = await api.get(apiUrl);
      setStudents(response.data.result);
      const responseEnrollmentStatus = await api.get(
        `${ENROLLMENT_STATUS_URL}?OnlyActive=true`
      );
      setEnrollmentStatusOptions(
        responseEnrollmentStatus.data.map((i) => ({
          value: i.id,
          label: i.name,
        }))
      );
      const responseSchoolBondResults = await api.get(
        `${BOND_RESULT_URL}?OnlyActive=true`
      );
      setSchoolBondResultOptions(
        responseSchoolBondResults.data.map((i) => ({
          value: i.id,
          label: i.name,
        }))
      );
    };
    getData();
  }, [isOpen]);

  useEffect(() => {
    if (students?.length > 0) {
      setData(
        students?.map((item) => ({
          id: item.id,
          name: item.name,
          schoolBonds: item.schoolBonds,
        }))
      );
    }
  }, [students]);

  const setNewData = (newData) => {
    setData(newData);
  };

  const patchResults = () => {
    let canPatchResults = true;

    const patchData = data.flatMap((obj) =>
      obj.schoolBonds
        .filter((schoolBond) => schoolBond.classFullName === className?.value)
        .map((schoolBond) => {
          const classBondedId = schoolBond.classBonded.id;

          const studentEnrollmentStatusBondedId =
            obj.studentEnrollmentStatusBondedId
              ? obj.studentEnrollmentStatusBondedId
              : schoolBond.studentEnrollmentStatusBonded?.id;

          let schoolBondResultBondedId = obj.schoolBondResultBondedId
            ? obj.schoolBondResultBondedId
            : schoolBond.schoolBondResultBonded?.id;

          if (studentEnrollmentStatusBondedId === 2) {
            schoolBondResultBondedId = 2;
          }

          if (
            studentEnrollmentStatusBondedId === 3 ||
            studentEnrollmentStatusBondedId === 4
          ) {
            schoolBondResultBondedId = null;
          }

          const courseCompletionAverage = obj.courseCompletionAverage
            ? obj.courseCompletionAverage
            : schoolBond.courseCompletionAverage;

          if (
            studentEnrollmentStatusBondedId === 1 &&
            schoolBondResultBondedId === 1 &&
            (classBondedId === 18 || classBondedId === 22) &&
            !courseCompletionAverage
          ) {
            return addToast({
              title: `Média de conclusão de curso do(a) aluno(a) ${obj.name} em falta`,
              status: "error",
            });
          }

          if (
            studentEnrollmentStatusBondedId === 1 &&
            schoolBondResultBondedId === 1 &&
            (classBondedId === 18 || classBondedId === 22) &&
            courseCompletionAverage
          ) {
            let number;
            if (isNaN(courseCompletionAverage)) {
              let string = courseCompletionAverage?.replace(",", ".");
              number = parseFloat(string);
            } else {
              number = courseCompletionAverage;
            }

            if (!isNaN(number) && number >= 10 && number <= 20) {
              if (!((number * 100) % 1 === 0)) {
                canPatchResults = false;
                return addToast({
                  title: `Valor da média de conclusão de curso do(a) aluno(a) ${obj.name} deve estar estar compreendido entre 10,00 e 20,00`,
                  status: "error",
                });
              }
            } else {
              canPatchResults = false;
              return addToast({
                title: `Valor da média de conclusão de curso do(a) aluno(a) ${obj.name} deve estar estar compreendido entre 10,00 e 20,00`,
                status: "error",
              });
            }
          }

          return {
            id: schoolBond.id,
            studentEnrollmentStatusBondedId: studentEnrollmentStatusBondedId,
            schoolBondResultBondedId: schoolBondResultBondedId,
            courseCompletionAverage: courseCompletionAverage
              ? courseCompletionAverage
              : null,
          };
        })
    );

    if (canPatchResults) {
      api
        .patch(`/api/Student/editStudentBondsInBulk`, {
          schoolBondsToEditBulk: patchData,
        })
        .then(() => {
          addToast({
            title: "Dados actualizados com sucesso",
            status: "success",
          });
          onClose();
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          for (let err in errors) {
            addToast({ title: errors[err], status: "error" });
          }
        });
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={!className?.value}
        rightIcon={<MdUpload />}
        mr={3}
      >
        Lançar Resultados
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lançar Resultados</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {students?.length > 0 && (
              <>
                <Text mb={3}>Turma: {className?.value}</Text>
                <TableStudentResults
                  data={data}
                  setNewData={setNewData}
                  className={className?.value}
                  classId={classId}
                  enrollmentStatusOptions={enrollmentStatusOptions}
                  schoolBondResultOptions={schoolBondResultOptions}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <Button onClick={patchResults}>Submeter</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default StudentResults;

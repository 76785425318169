import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Table from "../../../../components/Table";
import { NO_RESULTS } from "../../../../utils/constants";
import { AuthContext } from "../../../../provider/AuthProvider";
import { formStyles } from "../../../../utils/styles";
import { BsTrash } from "react-icons/bs";

export default function TableCourseManager({
  setDataTable,
  dataTable,
  academicYear,
  id,
  confirm,
  user,
}) {
  const { auth } = useContext(AuthContext);
  const isSchool = auth.isSchool;

  const [item, setItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddFieldOnObject = (fieldName, e, item) => {
    const newDataTable = dataTable;

    item.courseForAcademicYears.filter(
      (e) =>
        e.academicYearForSchool.academicYear.id === academicYear.value &&
        e.academicYearForSchool.school.id == id
    )[0][fieldName] = e;

    let indexToUpdate = dataTable.findIndex((obj) => obj.id === item.id);

    if (indexToUpdate !== -1) {
      newDataTable[indexToUpdate] = item;
    }

    setDataTable(newDataTable);
  };

  const columns = [
    {
      Header: "Código do Curso",
      accessor: "code",
    },
    {
      Header: "Designação do Curso",
      accessor: "name",
    },
    {
      Header: "Vagas da 7.ª ou 10.ª classes",
      disableSortBy: true,
      Cell: (item) => {
        let course = item.row.original.courseForAcademicYears?.filter(
          (e) =>
            e.academicYearForSchool.academicYear.id === academicYear.value &&
            e.academicYearForSchool.school.id == id
        )[0];
        return (
          <NumberInput
            defaultValue={course?.capacity}
            min={0}
            onChange={(e) => {
              handleAddFieldOnObject("capacity", e, item.row.original);
            }}
            isDisabled={!isSchool || confirm}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Candidaturas da 7.ª ou 10.ª classes",
      disableSortBy: true,
      Cell: (item) => {
        let course = item.row.original.courseForAcademicYears?.filter(
          (e) =>
            e.academicYearForSchool.academicYear.id === academicYear.value &&
            e.academicYearForSchool.school.id == id
        )[0];
        return (
          <NumberInput
            defaultValue={course?.applications}
            min={0}
            onChange={(e) => {
              handleAddFieldOnObject("applications", e, item.row.original);
            }}
            isDisabled={!isSchool || confirm}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Desassociar",
      accessor: "delete",
      disableSortBy: true,
      Cell: (item) => {
        return isSchool || !user.permissions.includes("EditCourses") ? (
          <Center _hover={{ cursor: "not-allowed" }}>
            <BsTrash />
          </Center>
        ) : (
          <Center>
            <Link>
              <BsTrash
                onClick={() => {
                  onOpen();
                  setItem(item.row.original);
                }}
              />
            </Link>
          </Center>
        );
      },
    },
  ];

  return (
    <>
      {dataTable ? (
        dataTable.length > 0 ? (
          <>
            <Table columns={columns} data={dataTable} isPaginated={false} />
            <AlertDialog isOpen={isOpen} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Remover Curso
                  </AlertDialogHeader>

                  <AlertDialogBody>Tem a certeza?</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button variant="secondary" onClick={onClose} mr={3}>
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => {
                        setDataTable(dataTable.filter((i) => i.id !== item.id));
                        setItem(null);
                        onClose();
                      }}
                    >
                      Remover
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        ) : (
          <Box sx={formStyles}>
            <Text>{NO_RESULTS}</Text>
          </Box>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}

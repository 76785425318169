import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Center, Spinner, Text } from "@chakra-ui/react";

import { Switch } from "@chakra-ui/react";

import { BsTrash } from "react-icons/bs";
import {
  COMMUNE_URL,
  DELETE_LOOKUP_ITEM,
  ENABLE_DISABLE_LOOKUP_ITEM,
} from "../../../../utils/endpoints";
import api from "../../../../utils/api";
import { Toast } from "../../../../components/Toast";
import Alert from "../../../../components/Alert";
import { DIALOG_MESSAGE, NO_RESULTS } from "../../../../utils/constants";
import { formStyles } from "../../../../utils/styles";
import Table from "../../../../components/Table";
import ChangeStatus from "../ChangeStatus";
import Edit from "../Edit";
import Delete from "../Delete";
import ModalProvinceEdit from "../Modal/ModalProvinceEdit";

export default function TableProvinceCommune({
  isLoaded,
  setIsLoaded,
  dataCommune,
  setDataCommune,
}) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [idItem, setIdItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const { addToast } = Toast();

  const handleDeleteItem = async () => {
    try {
      setLoading(true);
      await api.delete(DELETE_LOOKUP_ITEM, {
        data: {
          entityType: "Commune",
          id: idItem,
        },
      });
      const response = await api.get(
        `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
      );
      setDataCommune(
        response.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
      setIsAlertOpen(false);
      addToast({
        status: "success",
        title: "Item eliminado com sucesso",
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeStateItem = async (item) => {
    try {
      await api.post(ENABLE_DISABLE_LOOKUP_ITEM, {
        entityType: "Commune",
        id: item.id,
        enabled: !item.isActive,
      });
      const response = await api.get(
        `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
      );
      setDataCommune(
        response.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
      addToast({
        title: "Estado do item alterado com sucesso",
        status: "success",
      });
    } catch (e) {
      addToast({
        title: "Ocorreu um erro ao alterar o estado do item",
        status: "error",
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Província",
        accessor: "fromCounty.fromProvince.name",
      },
      {
        Header: "Estado",
        accessor: "fromCounty.fromProvince.status",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <ChangeStatus
              item={item.row.original.fromCounty.fromProvince}
              entityType="Province"
              entityName="Commune"
              setData={setDataCommune}
            />
          );
        },
      },
      {
        Header: "Editar",
        accessor: "fromCounty.fromProvince.edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Edit
                item={item.row.original.fromCounty.fromProvince}
                entityType="Province"
                entityName="Commune"
                setData={setDataCommune}
              />
            </Link>
          );
        },
      },
      {
        Header: "Excluir",
        accessor: "fromCounty.fromProvince.delete",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Delete
                item={item.row.original.fromCounty.fromProvince}
                entityType="Province"
                entityName="Commune"
                setData={setDataCommune}
              />
            </Link>
          );
        },
      },
      {
        Header: "Município",
        accessor: "fromCounty.name",
      },
      {
        Header: "Estado",
        accessor: "fromCounty.status",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <ChangeStatus
              item={item.row.original.fromCounty}
              entityType="County"
              entityName="Commune"
              setData={setDataCommune}
            />
          );
        },
      },
      {
        Header: "Editar",
        accessor: "fromCounty.edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Edit
                item={item.row.original.fromCounty}
                entityType="County"
                entityName="Commune"
                setData={setDataCommune}
              />
            </Link>
          );
        },
      },
      {
        Header: "Excluir",
        accessor: "fromCounty.delete",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Delete
                item={item.row.original.fromCounty}
                entityType="County"
                entityName="Commune"
                setData={setDataCommune}
              />
            </Link>
          );
        },
      },
      {
        Header: "Comuna",
        accessor: "name",
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Estado",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <ChangeStatus
              item={item.row.original}
              entityType="Commune"
              entityName="Commune"
              setData={setDataCommune}
            />
          );
        },
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Center>
              <Link>
                <ModalProvinceEdit
                  item={item}
                  entityName="Commune"
                  setDataCommune={setDataCommune}
                />
              </Link>
            </Center>
          );
        },
      },
      {
        Header: "Excluir",
        accessor: "delete",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Center>
              <Link>
                <BsTrash
                  onClick={() => {
                    setIdItem(item.row.original.id);
                    setIsAlertOpen(true);
                  }}
                />
              </Link>
            </Center>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <>
        {dataCommune ? (
          dataCommune.length > 0 ? (
            <Table
              setIsLoaded={setIsLoaded}
              isLoaded={isLoaded}
              setDataCommune={setDataCommune}
              // getFunction={getData}
              columns={columns}
              data={dataCommune}
              isPaginated={false}
            />
          ) : (
            <Box width="100%" display="flex" sx={formStyles}>
              <Text>{NO_RESULTS}</Text>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </>
      <Alert
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        text={DIALOG_MESSAGE}
        title="Excluir item"
        handleDelete={handleDeleteItem}
        buttonText="Excluir"
        loading={loading}
      />
    </>
  );
}

import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableSchools from "./TableSchools";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../provider/AuthProvider";
import { useForm } from "react-hook-form";
import { formStyles } from "../../../utils/styles";
import Filters from "./Filters";
import { ACADEMIC_YEAR_URL } from "../../../utils/endpoints";
import api from "../../../utils/api";

function TabSchools() {
  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { control, watch, setValue } = useForm();

  const [provinceId, setProvinceId] = useState(null);
  const [countyId, setCountyId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);

  async function getAcademicYearsOptions() {
    const response = await api.get(ACADEMIC_YEAR_URL);
    setAcademicYearOptions(
      response.data.result.map((i) => ({
        value: i.id,
        label: i.year,
      }))
    );
  }

  useEffect(() => {
    if (user.provinceId) {
      setProvinceId(user.provinceId);
    }
    if (user.countyId) {
      setCountyId(user.countyId);
    }
    if (user.schoolId) {
      setSchoolId(user.schoolId);
    }
    getAcademicYearsOptions();
  }, []);

  const academicYear = watch("academicYear");
  const province = watch("province");
  const county = watch("county");
  const school = watch("school");

  const data = [
    {
      id: 3,
      name: "Escolas e instalações por escola",
    },
    {
      id: 1,
      name: "Escolas com alunos matriculados",
    },
    {
      id: 2,
      name: "Vagas candidaturas e matrículas",
    },
  ];
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Filtrar dados das tabelas
        </Heading>
        <Box as="form" sx={formStyles} px="0!important">
          <Filters
            watch={watch}
            control={control}
            setValue={setValue}
            provinceId={provinceId}
            countyId={countyId}
            schoolId={schoolId}
            academicYearOptions={academicYearOptions}
          />
        </Box>
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableSchools
          user={user}
          data={data}
          academicYear={academicYear}
          province={province}
          county={county}
          school={school}
          provinceId={provinceId}
          countyId={countyId}
          schoolId={schoolId}
          academicYearOptions={academicYearOptions}
        />
      </Box>
    </Stack>
  );
}

export default TabSchools;

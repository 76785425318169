import api from "../../utils/api";
import Table from "../../components/Table";
import { useContext, useEffect, useMemo, useState } from "react";
import { SCHOOL_INVALIDATE, SCHOOL_VALIDATE } from "../../utils/endpoints";
import { Link } from "react-router-dom";
import { Box, Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { Toast } from "../../components/Toast";

import { Checkbox } from "@chakra-ui/react";
import { getSchools } from "../../services/getsSchools";
import { ARCHIVED, NO_RESULTS } from "../../utils/constants";
import StateRender from "../../components/StateRender";
import {
  MdArchive,
  MdCancel,
  MdCheckCircle,
  MdOutlineGridView,
} from "react-icons/md";
import { formStyles } from "../../utils/styles";
import { AuthContext } from "../../provider/AuthProvider";

function TableSchool({ setDataTable, dataTable, params, setValue }) {
  const { addToast } = Toast();
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [loadingInvalidate, setLoadingInvalidate] = useState(false);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const getData = async () => {
    try {
      const response = await getSchools(null, user.provinceId, user.countyId);
      setDataTable(response);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Seleccionar todas",
        disableSortBy: true,
        accessor: "checkbox",
        Cell: (item) => {
          return (
            <Checkbox
              isChecked={selectedRows.includes(item.row.original.id)}
              onChange={() => toggleRowSelection(item.row.original.id)}
            />
          );
        },
      },
      {
        Header: "Estado",
        Cell: (item) => {
          return item.row.original.isActive ? (
            <StateRender isValidated={item.row.original.isValidated} />
          ) : (
            <Flex gap={1}>
              <MdArchive size="20px" />
              {ARCHIVED}
            </Flex>
          );
        },
      },
      {
        Header: "Designação da Escola",
        accessor: "name",
      },
      {
        Header: "Província/Município",
        accessor: (row) =>
          `${row.fromCounty?.fromProvince?.name} / ${row?.fromCounty?.name}`,
      },
      {
        Header: "Natureza",
        accessor: "nature.name",
      },
      {
        Header: "Gerir",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link to={`/escolas/gerir/${item.row.original.id}`}>
              <Center>
                <MdOutlineGridView size={22} color="#555" />
              </Center>
            </Link>
          );
        },
      },
    ],
    [selectedRows]
  );

  const toggleRowSelection = (rowId) => {
    const updatedSelection = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelection);
  };

  async function validateSchool() {
    setLoadingValidate(true);
    try {
      await api.patch(SCHOOL_VALIDATE, {
        schools: selectedRows,
      });
      addToast({ title: "Escola validada com sucesso", status: "success" });
      getData();
      setValue("province", undefined);
      setValue("county", undefined);
      setValue("natures", undefined);
      setValue("type", undefined);
      setValue("state", undefined);
      setSelectedRows([]);
    } catch (error) {
      addToast({ title: error.message, status: "error" });
    } finally {
      setLoadingValidate(false);
    }
  }

  async function InvalidateSchool() {
    setLoadingInvalidate(true);
    try {
      await api.patch(SCHOOL_INVALIDATE, {
        schools: selectedRows,
      });
      addToast({ title: "Escola invalidada com sucesso", status: "success" });
      getData();
      setValue("province", undefined);
      setValue("county", undefined);
      setValue("natures", undefined);
      setValue("type", undefined);
      setValue("state", undefined);
      setSelectedRows([]);
    } catch (error) {
      addToast({ title: error.message, status: "error" });
    } finally {
      setLoadingInvalidate(false);
    }
  }

  return (
    <>
      <>
        {dataTable ? (
          dataTable.length > 0 ? (
            <Table
              columns={columns}
              data={dataTable}
              setData={setDataTable}
              entity="school"
              params={params}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          ) : (
            <Box width="100%" display="flex" sx={formStyles}>
              <Text>{NO_RESULTS}</Text>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </>

      {user.permissions.includes("ValidateSchool") && (
        <Box mt="5">
          <Button
            rightIcon={<MdCheckCircle />}
            onClick={validateSchool}
            isLoading={loadingValidate}
            isDisabled={loadingValidate}
            mr="2"
          >
            Validar Seleccionadas
          </Button>
          <Button
            variant="danger"
            rightIcon={<MdCancel />}
            onClick={InvalidateSchool}
            isLoading={loadingInvalidate}
            isDisabled={loadingInvalidate}
          >
            Não Validar Seleccionadas
          </Button>
        </Box>
      )}
    </>
  );
}

export default TableSchool;

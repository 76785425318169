import {
  FormControl,
  FormErrorMessage,
  HStack,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
import { REQUIRED_FIELD } from "../../../utils/constants";
import { borderRadius } from "../../../utils/styles";
import { borderColor } from "../../../utils/colors";

export default function FormTabInfo({
  errors,
  register,
  control,
  setValue,
  watch,
  setRegexFormatYear,
  fieldDisabled,
}) {
  const [formatYear, setFormatYear] = useState("");
  const [academicYearFormatData, setAcademicYearFormatData] = useState([]);

  const handleLabelInAcademicYear = (year) => {
    const label = year.includes("/")
      ? "4 + 4 dígitos - xxxx/xxxx"
      : "4 dígitos - xxxx";
    return label;
  };

  const inputCodeStyle = {
    padding: "10px",
    width: "100%",
    border: "1px solid",
    borderColor: borderColor,
    borderRadius: borderRadius,
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYearFormat();
      setAcademicYearFormatData(response);
    };
    getData();
  }, []);

  useEffect(() => {
    if (watch("yearFormat")) {
      const getData = async () => {
        const responseFormatYear = await getAcademicYearFormat(
          watch("yearFormat")
        );
        setRegexFormatYear(
          responseFormatYear.filter((e) => e.value === watch("yearFormat"))[0]
            .regex
        );
        setFormatYear(
          responseFormatYear
            .filter((e) => e.value === watch("yearFormat"))[0]
            .code.replaceAll("y", "9")
        );
      };
      getData();
    }
  }, [watch("yearFormat")]);

  return (
    <>
      <Stack spacing={5}>
        <FormControl isInvalid={!!errors.yearFormat}>
          <Label title="Formato" isRequired={true} />
          <Controller
            control={control}
            name="yearFormat"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                onChange={(e) => {
                  setValue("year", "");
                  onChange(Number(e));
                }}
                value={Number(value)}
              >
                <Stack direction="column">
                  {academicYearFormatData.map((e) => (
                    <Radio
                      onChange={() => {
                        setRegexFormatYear(e.regex);
                      }}
                      value={Number(e.value)}
                    >
                      {handleLabelInAcademicYear(e.code)}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>
            {errors.yearFormat && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>

        <HStack width="200px">
          <FormControl isInvalid={!!errors.year}>
            <Label title="Ano Lectivo" isRequired={true} />
            <InputMask
              {...register("year")}
              style={inputCodeStyle}
              mask={formatYear.replaceAll("x", "9")}
              placeholder={formatYear.replaceAll("9", "_")}
              disabled={fieldDisabled}
            />
            <FormErrorMessage>
              {errors.year && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}

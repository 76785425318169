import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import api from "../../../utils/api";
import {
  COMMUNE_URL,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
  UPDATE_ITEM_LOOKUP,
  URBANDISTRICT_URL,
} from "../../../utils/endpoints";
import { Toast } from "../../../components/Toast";
import { lookUps } from "..";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EditIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

export default function Edit({
  item,
  entityType,
  setDataTable,
  entityName,
  setData,
}) {
  const { register, watch, handleSubmit, setValue } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { addToast } = Toast();

  useEffect(() => {
    setValue("item_code", item.code);
    setValue("item_name", item.name);
  }, [item]);

  const item_code = watch("code");
  const item_name = watch("name");

  const { table } = useParams();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const bodyRequest = {
        Code: data.item_code,
        Name: data.item_name,
        EntityType: entityType,
      };

      await api.patch(UPDATE_ITEM_LOOKUP, {
        ...bodyRequest,
        id: item.id,
      });

      addToast({
        status: "success",
        title: "Item actualizado com sucesso",
      });

      if (entityName) {
        if (entityName === "UrbanDistrict") {
          const response = await api.get(
            `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "Commune") {
          const response = await api.get(
            `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "CourseGeneralArea") {
          const response = await api.get(GENERAL_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        } else if (entityName === "CourseSpecificArea") {
          const response = await api.get(SPECIFIC_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        }
      } else {
        const response = await lookUps[table - 1].getFunction({
          OnlyActive: false,
          PageSize: 2500,
        });
        setDataTable(response);
      }
      onClose();
    } catch (e) {
      addToast({
        status: "error",
        title: "Ocorreu um erro ao actualizar o item",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <EditIcon
        onClick={() => {
          onOpen();
        }}
      />
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Actualizar item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={5}>
                <Box>
                  <FormControl>
                    <Label title="Código" isRequired />
                    <Input
                      value={item_code}
                      {...register("item_code")}
                      size="md"
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl>
                    <Label title="Nome" isRequired />
                    <Input
                      value={item_name}
                      {...register("item_name")}
                      size="md"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button type="submit" isLoading={loading} isDisabled={loading}>
                Actualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

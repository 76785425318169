import { Accordion, Box, Heading, Stack } from "@chakra-ui/react";
import { formStyles } from "../../../utils/styles";
import EnrollmentModal from "./EnrollmentModal";
import DataAccordion from "./DataAccordion";

function TabSchoolData({
  isDisabled,
  id,
  schoolBondRequests,
  setSchoolBondRequests,
  schoolBondRequestsData,
  setSchoolBondRequestsData,
}) {
  return (
    <Box>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Dados Escolares
        </Heading>
        {!isDisabled && (
          <EnrollmentModal
            schoolBondRequests={schoolBondRequests}
            schoolBondRequestsData={schoolBondRequestsData}
            setSchoolBondRequests={setSchoolBondRequests}
            setSchoolBondRequestsData={setSchoolBondRequestsData}
          />
        )}
      </Box>
      <Box sx={formStyles}>
        <>
          {schoolBondRequestsData && schoolBondRequestsData.length > 0 && (
            <Stack spacing={5}>
              <Accordion allowToggle>
                {schoolBondRequestsData.map((i, index) => (
                  <DataAccordion
                    key={index}
                    id={id}
                    index={index}
                    isDisabled={isDisabled}
                    schoolBondRequests={schoolBondRequests}
                    setSchoolBondRequests={setSchoolBondRequests}
                    schoolBondRequestsData={schoolBondRequestsData}
                    setSchoolBondRequestsData={setSchoolBondRequestsData}
                    academicYear={
                      i.academicYearBonded?.year || i.academicYearBonded?.label
                    }
                    academicYearId={
                      i.academicYearBonded?.id || i.academicYearBonded?.value
                    }
                    school={i.schoolBonded?.name || i.schoolBonded?.label}
                    schoolId={i.schoolBonded?.id || i.schoolBonded?.value}
                    course={i.courseBonded?.name || i.courseBonded?.label}
                    courseTechnical={
                      i.courseTechnicalBonded?.name ||
                      i.courseTechnicalBonded?.label
                    }
                    courseEducationalSubsystem={
                      i.courseEducationalSubsystemBonded?.name ||
                      i.courseEducationalSubsystemBonded?.label
                    }
                    courseEducationalCycle={
                      i.courseEducationalCycleBonded?.name ||
                      i.courseEducationalCycleBonded?.label
                    }
                    studentEnrollmentStatus={
                      i.studentEnrollmentStatusBonded?.name ||
                      i.studentEnrollmentStatusBonded?.label
                    }
                    studentEnrollmentStatusValue={
                      i.studentEnrollmentStatusBonded?.id ||
                      i.studentEnrollmentStatusBonded?.value
                    }
                    studentClass={i.classBonded?.name || i.classBonded?.label}
                    studentClassValue={
                      i.classBonded?.id || i.classBonded?.value
                    }
                    classModality={
                      i.classModalityBonded?.name ||
                      i.classModalityBonded?.label
                    }
                    classShift={
                      i.classShiftBonded?.name || i.classShiftBonded?.label
                    }
                    classLetter={
                      i.classLetterBonded?.name || i.classLetterBonded?.label
                    }
                    classFullName={i.classFullName}
                    schoolBondResult={
                      i.schoolBondResultBonded?.name ||
                      i.schoolBondResultBonded?.label
                    }
                    schoolBondResultValue={
                      i.schoolBondResultBonded?.id ||
                      i.schoolBondResultBonded?.value
                    }
                    courseCompletionAverage={i.courseCompletionAverage}
                    hasLegalEquivalence={i.hasLegalEquivalence}
                    hasLegalProof={i.hasLegalProof}
                    isEditable={i.isEditable}
                  />
                ))}
              </Accordion>
            </Stack>
          )}
        </>
      </Box>
    </Box>
  );
}

export default TabSchoolData;

import api from "./api";

export function downloadExcelFile(apiURL, fileName, setLoading = null) {
  if (setLoading) {
    setLoading(true);
  }
  api
    .get(apiURL, { responseType: "arraybuffer" })
    .then((response) => {
      const result = response.data;
      const blob = new Blob([result], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";
import {
  COMMUNE_URL,
  DELETE_LOOKUP_ITEM,
  DETAILED_AREA_URL,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
  URBANDISTRICT_URL,
} from "../../../utils/endpoints";
import { lookUps } from "..";
import api from "../../../utils/api";
import { Toast } from "../../../components/Toast";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Delete({
  item,
  entityType,
  setDataTable,
  entityName,
  setData,
}) {
  const [hasChildren, setHasChildren] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { addToast } = Toast();
  const { table } = useParams();

  const getChildren = async (id) => {
    if (entityName) {
      if (entityName === "CourseGeneralArea") {
        const response = await api.get(`${SPECIFIC_AREA_URL}?ParentId=${id}`);
        if (response.data.length > 0) {
          setHasChildren(true);
        } else {
          setHasChildren(false);
        }
      } else if (entityName === "CourseSpecificArea") {
        const response = await api.get(`${DETAILED_AREA_URL}?ParentId=${id}`);
        if (response.data.length > 0) {
          setHasChildren(true);
        } else {
          setHasChildren(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      getChildren(item.id);
    }
  }, [isOpen]);

  const deleteItem = async (id) => {
    setLoading(true);
    try {
      await api.delete(DELETE_LOOKUP_ITEM, {
        data: {
          entityType: entityType,
          id: id,
        },
      });

      addToast({
        status: "success",
        title: "Item eliminado com sucesso",
      });

      if (entityName) {
        if (entityName === "UrbanDistrict") {
          const response = await api.get(
            `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "Commune") {
          const response = await api.get(
            `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "CourseGeneralArea") {
          const response = await api.get(GENERAL_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        } else if (entityName === "CourseSpecificArea") {
          const response = await api.get(SPECIFIC_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        }
      } else {
        const response = await lookUps[table - 1].getFunction({
          OnlyActive: false,
          PageSize: 2500,
        });
        setDataTable(response);
      }
      onClose();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BsTrash onClick={onOpen} />
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir item
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button
                variant="danger"
                onClick={() => deleteItem(item.id)}
                isDisabled={hasChildren || loading}
                isLoading={loading}
              >
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
// import { Link } from "react-router-dom";
import { formStyles } from "../../utils/styles";
import Label from "../../components/Label";
import { AuthContext } from "../../provider/AuthProvider";
import { useContext, useEffect, useState } from "react";
import api from "../../utils/api";
import { EDIT_USER_URL, USER_PASSWORD, USER_URL } from "../../utils/endpoints";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { INVALID_EMAIL, REQUIRED_FIELD } from "../../utils/constants";
import { Toast } from "../../components/Toast";

export default function EditAccount() {
  const [userData, setUserData] = useState([]);
  const { auth } = useContext(AuthContext);
  const { addToast } = Toast();
  const [error, setError] = useState("");

  const schema = z.object({
    currentPassword: z.string().nonempty(REQUIRED_FIELD),
    newPassword: z
      .string()
      .nonempty(REQUIRED_FIELD)
      .min(8, "A senha deve ter pelo menos 8 caracteres")
      .regex(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .regex(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .regex(/[0-9]/, "A senha deve conter pelo menos um número")
      .regex(
        /[^A-Za-z0-9]/,
        "A senha deve conter pelo menos um caractere especial"
      ),
    confirmPassword: z.string().nonempty(REQUIRED_FIELD),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    setValue,
    control,
  } = useForm();

  useEffect(() => {
    api.get("/api/User/getselfdata").then((response) => {
      const data = response.data;
      setUserData(data);
      setValue("email", data.email);
    });
  }, []);

  let roleNames = "";

  for (let i = 0; i < userData?.roles?.length; i++) {
    const role = userData.roles[i];
    const roleName = role.name;

    if (i === 0) {
      roleNames += roleName;
    } else {
      roleNames += `, ${roleName}`;
    }
  }

  const onSubmitEmail = (data) => {
    api
      .patch(EDIT_USER_URL, {
        id: userData.id,
        name: userData.name,
        email: data.email,
        roles: userData.roles.map((role) => role.id),
      })
      .then(() => {
        addToast({
          title: "Email alterado com sucesso",
          status: "success",
        });
        reset();
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("As senhas não coincidem");
    } else {
      setError("");

      api
        .patch(USER_PASSWORD, {
          currentPassword: data.currentPassword,
          password: data.newPassword,
        })
        .then(() => {
          addToast({
            title: "Senha alterada com sucesso",
            status: "success",
          });
          reset();
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          for (let err in errors) {
            addToast({ title: errors[err], status: "error" });
          }
        });
    }
  };

  return (
    <>
      <Stack spacing={5}>
        <Heading as="h1">Editar conta</Heading>

        <Box>
          <Heading
            as="h2"
            size="md"
            textTransform="uppercase"
            my="10px"
            display="block"
          >
            Informação
          </Heading>

          <Box>
            <Box sx={formStyles} mb={5}>
              <Stack spacing={4}>
                <FormControl>
                  <Label title="Nome" />
                  <Input value={userData?.name} isDisabled={true} />
                </FormControl>

                <FormControl>
                  <Label title="Perfil" />
                  <Input value={roleNames} isDisabled={true} />
                </FormControl>

                {userData?.roles &&
                userData.roles?.some((role) => role.name === "SuperAdmin") ? (
                  <Box as="form">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: REQUIRED_FIELD,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: INVALID_EMAIL,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl isInvalid={fieldState?.error}>
                          <Label title="Email" isRequired />
                          <Input {...field} />
                          <FormErrorMessage>
                            {fieldState?.error && (
                              <p>{fieldState.error.message}</p>
                            )}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Button
                      mt={4}
                      type="button"
                      onClick={handleSubmitEmail(onSubmitEmail)}
                    >
                      Actualizar
                    </Button>
                  </Box>
                ) : (
                  <FormControl>
                    <Label title="Email" />
                    <Input value={userData?.email} isDisabled={true} />
                  </FormControl>
                )}

                <>
                  {userData.length > 0 && userData.userSchool !== null && (
                    <FormControl>
                      <Label title="Escola" isRequired />
                      <Input
                        value={userData?.userSchool?.name}
                        isDisabled={true}
                      />
                    </FormControl>
                  )}

                  {userData.length > 0 && userData.userProvince !== null && (
                    <FormControl>
                      <Label title="Província" isRequired />
                      <Input
                        value={userData?.userProvince?.name}
                        isDisabled={true}
                      />
                    </FormControl>
                  )}

                  {userData.length > 0 && userData?.userCounty !== null && (
                    <FormControl>
                      <Label title="Município" isRequired />
                      <Input
                        value={userData?.userCounty?.name}
                        isDisabled={true}
                      />
                    </FormControl>
                  )}
                </>

                <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!errors.confirmPassword}>
                      <Label title="Senha actual" isRequired />
                      <Input type="password" {...register("currentPassword")} />

                      <FormErrorMessage>
                        {errors.currentPassword &&
                          errors.currentPassword.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.newPassword}>
                      <Label title="Nova Senha" isRequired />
                      <Input type="password" {...register("newPassword")} />

                      <FormErrorMessage>
                        {errors.newPassword && errors.newPassword.message}
                        {error.newPassword &&
                          error.newPassword.issues.map((issue, index) => (
                            <div key={index}>{issue.message}</div>
                          ))}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.confirmPassword || !!error}
                    >
                      <Label title="Confirmar Nova Senha" isRequired />
                      <Input type="password" {...register("confirmPassword")} />

                      <FormErrorMessage>
                        {errors.confirmPassword &&
                          errors.confirmPassword.message}
                        {!!error && error}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box>
              <Button type="button" onClick={handleSubmit(onSubmit)}>
                Guardar
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
}

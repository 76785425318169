import { isEditable } from "@testing-library/user-event/dist/utils";
import api from "../utils/api";
import { COURSE_URL } from "../utils/endpoints";

export async function getCourseById(id) {
  try {
    const response = await api.get(`${COURSE_URL}/${id}`);
    const data = {
      id: response.data.id,
      code: response.data.code,
      name: response.data.name,
      className: response.data.className,
      educationLevel: response.data.educationalLevel,
      subsystemEducation: response.data.educationalSubsystem,
      educationCicle: response.data.educationalCycle,
      generalArea: response.data.generalArea,
      specificArea: response.data.specificArea,
      detailedArea: response.data.detailedArea,
      technicalEducation: response.data.courseTechnical,
      domainPNFQ: response.data.pnfqDomain,
      isValidated: response.data.isValidated,
      subjects: response.data.subjects,
      schools: response.data.courseForAcademicYears,
      isActive: response.data.isActive,
      courseForAcademicYears: response.data.courseForAcademicYears,
      isEditable: response.data.isEditable,
    };
    return data;
  } catch (error) {
    throw error;
  }
}

import { useContext, useEffect, useState } from "react";
import { Heading, Stack, Text, Box, Flex, Button } from "@chakra-ui/react";
import { formStyles } from "../../utils/styles";
import SearchArea from "../../components/SearchArea";
import FilterArea from "../../components/FilterArea";
import TableTeacher from "./components/TableTeacher";
import ButtonGroupTeacher from "./components/ButtonGroupTeacher";
import { Toast } from "../../components/Toast";
import { useForm } from "react-hook-form";
import handleFilterOrSearch from "../../helpers/handleFilterOrSearch";
import ModalConfirmTeacher from "./components/Modal";
import { getTeacher } from "../../services/getTeacher";
import { getBond } from "../../services/getBond";
import { getTrainingArea } from "../../services/getTrainingArea";
import { getAcademicQualification } from "../../services/getAcademicQualification";
import { getAcademicYear } from "../../services/getAcademicYear";
import { getSchool } from "../../services/getSchool";
import { getPedagogicalTraining } from "../../services/getPedagogicalTraining";
import { getCourse } from "../../services/getCourse";
import { getSubject } from "../../services/getSubject";
import { TEACHER_SUMMARY } from "../../utils/constants";
import { AuthContext } from "../../provider/AuthProvider";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

function Teacher() {
  const [schoolData, setSchoolData] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [academicYearData, setAcademicYearData] = useState([]);
  const [academicQualificationData, setAcademicQualificationData] = useState(
    []
  );
  const [trainningAreaData, setTrainningArea] = useState([]);
  const [pedagogicalTrainingData, setPedagogicalTrainingData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const { addToast } = Toast();
  const { register, control, watch, setValue } = useForm();

  const inputSearch = watch("search");
  const academicQualification = watch("academicQualification");
  const trainingArea = watch("trainingArea");
  const school = watch("school");
  const contractualBondType = watch("contractualBond");
  const pedagogicalTraining = watch("pedagogicalTraining");
  const course = watch("course");
  const subject = watch("subject");
  const state = watch("state");
  const isActive = watch("isActive");

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const dataSelect = [
    {
      fieldObjectName: "school",
      fieldName: "Escola",
      data: schoolData,
    },
    {
      fieldObjectName: "academicQualification",
      fieldName: "Habilitação Académica",
      data: academicQualificationData,
    },
    {
      fieldObjectName: "trainingArea",
      fieldName: "Área de Formação",
      data: trainningAreaData,
    },
    {
      fieldObjectName: "contractualBond",
      fieldName: "Vínculo",
      data: linkData,
    },
    {
      fieldObjectName: "pedagogicalTraining",
      fieldName: "Formação Pedagógica",
      data: pedagogicalTrainingData,
    },
    {
      fieldObjectName: "course",
      fieldName: "Curso",
      data: courseData,
    },
    {
      fieldObjectName: "subject",
      fieldName: "Disciplina",
      data: subjectData,
    },
    {
      fieldObjectName: "state",
      fieldName: "Estado",
      data: [
        { value: true, label: "Validado" },
        { value: "pending", label: "Pendente de Validação" },
        { value: false, label: "Não validado" },
      ],
    },
    {
      fieldObjectName: "isActive",
      fieldName: "Arquivado",
      data: [
        { value: false, label: "Sim" },
        { value: true, label: "Não" },
      ],
    },
  ];

  const paramsObj = {
    Pesquisa: inputSearch,
    AcademicQualification: academicQualification?.value,
    SchoolId: school?.value,
    TrainingArea: trainingArea?.value,
    ContractualBondType: contractualBondType?.value,
    PedagogicalTraining: pedagogicalTraining?.value,
    Courses: course?.id,
    Subjects: subject?.value,
    IsValidated: state?.value,
    IsActive: isActive?.value,
    ProvinceId: user?.provinceId ? user?.provinceId : undefined,
    CountyId: user?.countyId ? user?.countyId : undefined,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const handleSearch = async () => {
    setIsFilter(true);
    const data = await handleFilterOrSearch(paramsObj, addToast, getTeacher);
    setDataTable(data);
  };

  const handleCleanSearch = async () => {
    setValue("search", undefined);
    let valParams = [];
    for (const key in paramsObj) {
      valParams.push(paramsObj[key]);
    }

    const response = valParams.includes(undefined)
      ? await handleFilterOrSearch(
          { ...paramsObj, Pesquisa: undefined },
          addToast,
          getTeacher
        )
      : await getTeacher();
    setDataTable(response);
  };

  const handleCleanFilters = async () => {
    setValue("school", undefined);
    setValue("academicQualification", undefined);
    setValue("trainingArea", undefined);
    setValue("contractualBond", undefined);
    setValue("pedagogicalTraining", undefined);
    setValue("course", undefined);
    setValue("subject", undefined);
    setValue("state", undefined);
    setValue("isActive", undefined);

    let response;
    if (inputSearch !== undefined) {
      response = await handleFilterOrSearch(
        {
          Pesquisa: inputSearch,
          ProvinceId: user?.provinceId ? user?.provinceId : undefined,
          CountyId: user?.countyId ? user?.countyId : undefined,
        },
        addToast,
        getTeacher
      );
    } else {
      response = await getTeacher();
    }
    setDataTable(response);
  };

  useEffect(() => {
    const getData = async () => {
      let responseSchool;
      if (user.countyId) {
        responseSchool = await getSchool(null, user.countyId);
      } else if (user.provinceId) {
        responseSchool = await getSchool(user.provinceId);
      } else {
        responseSchool = await getSchool();
      }
      const responseAcademicYear = (await getAcademicYear()).map((e) => ({
        label: e.year,
        value: e.id,
      }));
      const responseAcademicQualification = await getAcademicQualification();
      const responseTrainingArea = await getTrainingArea();
      const responseBond = await getBond();
      const responsePedagogicalTraining = await getPedagogicalTraining();
      const responseCourse = await getCourse();
      const responseSubject = await getSubject();

      setAcademicYearData(responseAcademicYear);
      setSchoolData(responseSchool);
      setAcademicQualificationData(responseAcademicQualification);
      setTrainningArea(responseTrainingArea);
      setLinkData(responseBond);
      setPedagogicalTrainingData(responsePedagogicalTraining);
      setCourseData(responseCourse);
      setSubjectData(responseSubject);
    };
    getData();
  }, []);

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Docentes</Heading>
        <Text width="sm">{TEACHER_SUMMARY}</Text>
      </Box>

      {user.permissions.includes("CreateTeachers") && (
        <ButtonGroupTeacher isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>

        <Box as="form" sx={formStyles} px="0!important">
          <SearchArea
            register={register}
            handleCleanFilters={handleCleanSearch}
            handleSearch={handleSearch}
            title={
              "Pesquisar pelo número de identificação ou código do docente"
            }
          />
          <FilterArea
            control={control}
            handleSearch={handleSearch}
            handleCleanFilters={handleCleanFilters}
            dataSelect={dataSelect}
            title="Filtrar Docentes"
            gridNumberSelect={5}
          />
        </Box>
      </Box>

      <Box>
        <TableTeacher
          setDataTable={setDataTable}
          dataTable={dataTable}
          params={paramsObj}
          isFilter={isFilter}
          setValue={setValue}
        />

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-docentes-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
      <ModalConfirmTeacher
        title="Cadastrar Docente"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Stack>
  );
}

export default Teacher;

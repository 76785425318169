import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableErrorCloseAcademicYear from "./TableErrorToCloseAcademicYear";

export default function ErrorToCloseAcademicYear({ data }) {
  return (
    <Stack spacing={5}>
      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="sm" textTransform="uppercase">
            Lista de Erros
          </Heading>
        </Flex>

        <TableErrorCloseAcademicYear data={data} />
      </Box>
    </Stack>
  );
}

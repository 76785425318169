import { Accordion, Box, Heading, Stack } from "@chakra-ui/react";
import { formStyles } from "../../../utils/styles";
import SubjsctsModal from "./SubjectsModal";
import SubjectsAccordion from "./SubjectsAccordion";

function TabTeacherSubjects({
  isDisabled,
  id,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
}) {
  return (
    <Box>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Cursos e Disciplinas
        </Heading>
        {!isDisabled && (
          <SubjsctsModal
            contractualBondRequests={contractualBondRequests}
            setContractualBondRequests={setContractualBondRequests}
            contractualBondRequestsData={contractualBondRequestsData}
            setContractualBondRequestsData={setContractualBondRequestsData}
          />
        )}
      </Box>
      <Box sx={formStyles}>
        <>
          {contractualBondRequestsData &&
            contractualBondRequestsData.length > 0 && (
              <Stack spacing={5}>
                <Accordion allowToggle>
                  {contractualBondRequestsData.map((i, index) => {
                    const hasCoursesOrSubjects =
                      i.coursesBound?.length > 0 || i.subjectsBound?.length > 0;
                    if (hasCoursesOrSubjects) {
                      return (
                        <SubjectsAccordion
                          key={index}
                          id={id}
                          index={index}
                          isDisabled={isDisabled}
                          contractualBondRequests={contractualBondRequests}
                          setContractualBondRequests={
                            setContractualBondRequests
                          }
                          contractualBondRequestsData={
                            contractualBondRequestsData
                          }
                          setContractualBondRequestsData={
                            setContractualBondRequestsData
                          }
                          academicYear={
                            i.academicYearBonded?.year ||
                            i.academicYearBonded?.label
                          }
                          school={i.schoolBonded?.name || i.schoolBonded?.label}
                          schoolId={i.schoolBonded?.id || i.schoolBonded?.value}
                          courses={i.coursesBound
                            ?.map((i) => i.name || i.label)
                            ?.join(", ")}
                          subjects={i.subjectsBound
                            ?.map((i) => i.name || i.label)
                            ?.join(", ")}
                          isEditable={i.isEditable}
                        />
                      );
                    }
                  })}
                </Accordion>
              </Stack>
            )}
        </>
      </Box>
    </Box>
  );
}

export default TabTeacherSubjects;

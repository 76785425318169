import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import { CrumbContext } from "../../provider/CrumbProvider";

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const { auth } = useContext(AuthContext);
  const { crumb: crumbState } = useContext(CrumbContext);
  const isSchool = auth.isSchool;
  const schoolId = isSchool ? auth.user.schoolId : "";
  let isSchoolManager = false;
  let hasChild = false;
  let childName = "";
  let schoolName = "";

  if (pathSegments.length > 1) {
    hasChild = true;
    if (pathSegments.includes("gerir")) {
      isSchoolManager = true;
      schoolName = crumbState["school"];
    }
    if (pathSegments.includes("utilizadores")) {
      childName = crumbState["user"];
    }
    if (pathSegments.includes("perfis")) {
      childName = crumbState["role"];
    }
    if (pathSegments.includes("escolas")) {
      childName = crumbState["school"];
    }
    if (pathSegments.includes("cursos")) {
      childName = crumbState["course"];
    }
    if (pathSegments.includes("alunos")) {
      childName = crumbState["student"];
    }
    if (pathSegments.includes("docentes")) {
      childName = crumbState["teacher"];
    }
    if (pathSegments.includes("anos-lectivos")) {
      childName = crumbState["academicYear"];
    }
    if (pathSegments.includes("configuracoes")) {
      childName = "Editar";
    }
    if (pathSegments.includes("carregar-lista")) {
      childName = "Carregar Lista";
    }
    if (pathSegments.includes("cadastrar")) {
      childName = "Cadastrar";
    }
    if (pathSegments.includes("criar")) {
      childName = "Criar";
    }
  }

  const segmentsList = [
    {
      name: "anos-lectivos",
      label: "Anos Lectivos",
    },
    {
      name: "estatisticas",
      label: "Estatísticas",
    },
    {
      name: "configuracoes",
      label: "Configurações",
    },
    {
      name: "editar-conta",
      label: "Editar conta",
    },
  ];

  return (
    <Breadcrumb spacing="4px" separator={<ChevronRightIcon />}>
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={isSchool ? `/gerir/${schoolId}` : `/inicio`}
          textDecoration="underline"
        >
          Início
        </BreadcrumbLink>
      </BreadcrumbItem>
      {pathSegments[0] === "gerir" && pathSegments.length === 2 ? (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink fontWeight="bold">{schoolName}</BreadcrumbLink>
        </BreadcrumbItem>
      ) : (
        pathSegments[0] !== "inicio" &&
        pathSegments.map((segment, index, array) => {
          if (segment === "gerir") return null;
          if (segment === "items") return null;
          if (segment === "associar") return null;
          let segmentLabel = "";
          if (segmentsList.find((item) => item.name === segment)) {
            segmentLabel = segmentsList.find(
              (item) => item.name === segment
            ).label;
          } else {
            segmentLabel = segment;
          }
          return (
            <BreadcrumbItem
              key={index}
              isCurrentPage={index + 1 === array.length}
            >
              <BreadcrumbLink
                {...(index + 1 === array.length ? {} : { as: Link })}
                {...(index + 1 === array.length
                  ? {}
                  : { to: `/${pathSegments.slice(0, index + 1).join("/")}` })}
                {...(index + 1 === array.length ? { fontWeight: "bold" } : {})}
                {...(index + 1 === array.length
                  ? {}
                  : { textDecoration: "underline" })}
              >
                {isSchoolManager ? (
                  index + 1 === array.length ? (
                    !isNaN(segmentLabel) ? (
                      childName
                    ) : (
                      <Text textTransform="capitalize">{segmentLabel}</Text>
                    )
                  ) : !isNaN(segmentLabel) ? (
                    schoolName
                  ) : (
                    <Text textTransform="capitalize">{segmentLabel}</Text>
                  )
                ) : index + 1 === array.length && hasChild ? (
                  childName
                ) : (
                  <Text textTransform="capitalize">{segmentLabel}</Text>
                )}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

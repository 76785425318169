import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../provider/AuthProvider";
import { Box, Button, Flex } from "@chakra-ui/react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DeleteStudent from "./DeleteStudent";
import ArchiveStudent from "./ArchiveStudent";

function FormButtons({
  isDisabled,
  setIsDisabled,
  id,
  handleTabChange,
  activeTab,
  createStudent,
  cancelEdit,
  isActive,
}) {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Box>
      {isDisabled ? (
        id ? (
          <>
            {user.permissions.includes("EditStudents") && isActive && (
              <Box mb={4}>
                <Button onClick={() => setIsDisabled(false)}>
                  Editar Aluno
                </Button>
              </Box>
            )}
            <Flex direction="row" gap={3}>
              {user.permissions.includes("EditStudents") && (
                <ArchiveStudent id={id} isActive={isActive} />
              )}
              {user.permissions.includes("DeleteStudents") && (
                <DeleteStudent id={id} />
              )}
            </Flex>
          </>
        ) : (
          <>
            <Button mr={4} onClick={createStudent}>
              Confirmar
            </Button>
            <Button
              variant="link"
              onClick={() => {
                handleTabChange(0);
                setIsDisabled(false);
              }}
            >
              Voltar a editar
            </Button>
          </>
        )
      ) : id ? (
        activeTab === 0 ? (
          <Flex justifyContent="space-between">
            <Button variant="secondary" onClick={cancelEdit}>
              Cancelar
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(1);
              }}
              mr={3}
              rightIcon={<MdKeyboardArrowRight />}
            >
              Dados Escolares
            </Button>
          </Flex>
        ) : (
          <Flex justifyContent="space-between">
            <Box>
              <Button type="submit" name="create" mr={3}>
                Guardar
              </Button>
              <Button variant="secondary" onClick={cancelEdit}>
                Cancelar
              </Button>
            </Box>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(0);
              }}
              leftIcon={<MdKeyboardArrowLeft />}
            >
              Informações do Aluno
            </Button>
          </Flex>
        )
      ) : activeTab === 0 ? (
        <Flex justifyContent="space-between">
          <Button
            variant="secondary"
            onClick={() => {
              if (!user?.schoolId) {
                navigate("/alunos");
              } else {
                navigate(`/gerir/${user?.schoolId}/alunos`);
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            name="paginate"
            variant="secondary"
            rightIcon={<MdKeyboardArrowRight />}
          >
            Dados Escolares
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent="space-between">
          <Box>
            <Button type="submit" name="create" mr={3}>
              Matricular Aluno
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                if (!user?.schoolId) {
                  navigate("/alunos");
                } else {
                  navigate(`/gerir/${user?.schoolId}/alunos`);
                }
              }}
            >
              Cancelar
            </Button>
          </Box>
          <Box>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(0);
              }}
              leftIcon={<MdKeyboardArrowLeft />}
            >
              Informações do Aluno
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default FormButtons;

import {
  Box,
  Input,
  Stack,
  Button,
  Grid,
  Flex,
  Center,
} from "@chakra-ui/react";
import ModalAssociate from "../components/Modal";
import { useEffect, useState, useMemo } from "react";
import { MdAddCircle } from "react-icons/md";
import Table from "../../../components/Table";
import handleInputOnChange from "../../../helpers/handleInputOnChange";
import handleSearch from "../../../helpers/handleSearch";
import Alert from "../../../components/Alert";
import { formStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { handleEnterOnSearch } from "../../../utils/constants";
import { getSubject } from "../../../services/getSubject";
import { BsTrash } from "react-icons/bs";

export default function FormTabSubject({
  setSelectedOptionsSubject,
  selectedOptionsSubject,
  dataTable,
  setDataTable,
  handleDesassociate,
  actualRow,
  setActualRow,
  optionsSubject,
  setOptionsSubject,
  isOpenAlert,
  setIsOpenAlert,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Código da Disciplina",
        accessor: "code",
      },
      {
        Header: "Designação da Disciplina",
        accessor: "label",
      },
      {
        Header: "Desassociar",
        accessor: "delete",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Center _hover={{ cursor: "pointer" }}>
              <BsTrash
                onClick={function () {
                  setIsOpenAlert(true);
                  setActualRow(item);
                }}
                to={`${item.row.original.id}`}
              />
            </Center>
          );
        },
      },
    ],
    [dataTable, setSelectedOptionsSubject]
  );
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getSubject({ PageSize: 2500 });
        setOptionsSubject(res);
        if (selectedOptionsSubject.length > 0) {
          setDataTable(selectedOptionsSubject);
        }
      } catch (e) {}
    };
    getData();
  }, []);

  const handleInputOnChangeCall = (e) => {
    handleInputOnChange(
      e,
      setDataTable,
      setInputSearch,
      selectedOptionsSubject
    );
  };

  const handleSearchCall = () => {
    const changedKeyNames = dataTable.map((e) => ({
      code: e.code,
      name: e.label,
      id: e.value,
    }));
    handleSearch(inputSearch, setDataTable, changedKeyNames);
  };
  return (
    <>
      <ModalAssociate
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        selectedOptions={selectedOptionsSubject}
        options={optionsSubject}
        setSelectedOptions={setSelectedOptionsSubject}
        title="Associar disciplina"
        setDataTable={setDataTable}
        dataTable={dataTable}
        setOptions={setOptionsSubject}
      />
      <Stack spacing={5} mb={5}>
        <Box sx={formStyles} px="0!important">
          <Box px="20px">
            <Label title="Pesquisar" />

            <Grid gridTemplateColumns="1fr auto">
              <Flex>
                <Input
                  onChange={handleInputOnChangeCall}
                  placeholder="Pesquisar por designação ou código da disciplina"
                  sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  onKeyDown={(e) => {
                    handleEnterOnSearch(e, handleSearchCall);
                  }}
                />

                <Button
                  onClick={handleSearchCall}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Pesquisar
                </Button>
              </Flex>
            </Grid>
          </Box>
        </Box>

        <Box>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            rightIcon={<MdAddCircle />}
          >
            Associar disciplina
          </Button>
        </Box>

        <Table columns={columns} data={dataTable} isPaginated={false} />
      </Stack>
      <Alert
        title="Desassociar disciplina"
        buttonText="Confirmar"
        handleDelete={handleDesassociate}
        isOpen={isOpenAlert}
        setIsOpen={setIsOpenAlert}
        text="Tem a certeza que pretende desassociar a disciplina?"
      />
    </>
  );
}

import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableGeneralData from "./TableGeneralData";
import { useEffect, useState } from "react";
import api from "../../../utils/api";
import { ACADEMIC_YEAR_URL } from "../../../utils/endpoints";

function TabGeneralData() {
  const [academicYearOptions, setAcademicYearOptions] = useState([]);

  async function getAcademicYearsOptions() {
    const response = await api.get(ACADEMIC_YEAR_URL);
    setAcademicYearOptions(
      response.data.result.map((i) => ({
        value: i.id,
        label: i.year,
      }))
    );
  }

  useEffect(() => {
    getAcademicYearsOptions();
  }, []);

  const data = [
    {
      id: 1,
      name: "Dados globais do ensino técnico-profissional",
    },
  ];

  return (
    <Stack spacing={5}>
      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableGeneralData
          data={data}
          academicYearOptions={academicYearOptions}
        />
      </Box>
    </Stack>
  );
}

export default TabGeneralData;

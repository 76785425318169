import api from "../utils/api";
import { ACADEMIC_YEAR_URL } from "../utils/endpoints";

export async function getAcademicYear(params) {
  try {
    let qs = "";
    qs += params?.CurrentPage ? `&CurrentPage=${params.CurrentPage}` : "";
    qs += params?.PageSize ? `&PageSize=${params.PageSize}` : "";
    const response = await api.get(`${ACADEMIC_YEAR_URL}?${qs}`);
    const data = response.data.result.map((option) => ({
      id: option.id,
      year: option.year,
      open: option.open,
      closeable: option.closeable,
      isActive: option.isActive,
      yearFormatId: option.yearFormatId,
      totalItems: response.data.totalItems,
      academicYearForSchools: option.academicYearForSchools,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}

import { useContext, useState } from "react";
import { Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import TableAcademicYear from "./components/TableAcademicYear";
import ButtonGroupCourse from "./components/ButtonGroupCourse";

import { ACADEMIC_YEAR_SUMMARY } from "../../utils/constants";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { AuthContext } from "../../provider/AuthProvider";

function AcademicYear() {
  const [dataTable, setDataTable] = useState([]);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Stack spacing={5}>
      <Heading>Anos Lectivos</Heading>
      <Text width="sm">{ACADEMIC_YEAR_SUMMARY}</Text>

      {user.permissions.includes("CreateAcademicYear") && <ButtonGroupCourse />}

      <TableAcademicYear setDataTable={setDataTable} dataTable={dataTable} />

      <Flex justifyContent="end" mt={3}>
        <Link to="/docs/manual-escolas-sietp.pdf" target="_blank">
          <Button variant="secondary" rightIcon={<MdArrowOutward />}>
            Consultar Manual de Apoio
          </Button>
        </Link>
      </Flex>
    </Stack>
  );
}

export default AcademicYear;

import { Button, Flex } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { FormSchool } from "./Forms";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { schoolSchema } from "./Forms/schoolSchema";
import { MdArrowOutward } from "react-icons/md";

export default function EditSchool() {
  const [isDisabled, setIsDisabled] = useState(true);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schoolSchema),
  });

  return (
    <>
      <FormSchool
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        watch={watch}
        id={id}
        setValue={setValue}
        control={control}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        unregister={unregister}
        getValues={getValues}
      />
      <Flex justifyContent="end" mt={3}>
        <Link to="/docs/manual-escolas-sietp.pdf" target="_blank">
          <Button variant="secondary" rightIcon={<MdArrowOutward />}>
            Consultar Manual de Apoio
          </Button>
        </Link>
      </Flex>
    </>
  );
}

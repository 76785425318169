import { useMemo } from "react";
import Table from "../../components/Table";
import Details from "./components/Details";

function TableAudit({
  data,
  isLoaded,
  setData,
  setIsLoaded,
  entity,
  apiUrl,
  total,
  isFromPagination,
  setIsFromPagination,
}) {
  let columns = useMemo(
    () => [
      {
        Header: "Acção",
        accessor: "id",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Details
              id={item.row.original.id}
              data={item.row.original.entityData}
              text={item.row.original.id}
            />
          );
        },
      },
      {
        Header: "Tipologia",
        accessor: "entityType",
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "entityId",
        disableSortBy: true,
      },
      {
        Header: "Nome",
        accessor: "entityName",
        disableSortBy: true,
      },
      {
        Header: "CRUD",
        accessor: "operationName",
        disableSortBy: true,
      },
      {
        Header: "Utilizador",
        accessor: "modifiedBy.name",
        disableSortBy: true,
      },
      {
        Header: "Data/Hora",
        accessor: "modifiedDate",
        disableSortBy: true,
        Cell: (item) => {
          const inputDate = new Date(item.row.original.modifiedDate);

          const day = inputDate.getDate().toString().padStart(2, "0");
          const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
          const year = inputDate.getFullYear();

          const hours = inputDate.getHours().toString().padStart(2, "0");
          const minutes = inputDate.getMinutes().toString().padStart(2, "0");
          const seconds = inputDate.getSeconds().toString().padStart(2, "0");

          const formattedDateString = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
          return formattedDateString;
        },
      },
      {
        Header: "Detalhes",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Details
              id={item.row.original.id}
              data={item.row.original.entityData}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {data && (
        <Table
          setData={setData}
          setIsLoaded={setIsLoaded}
          columns={columns}
          data={data}
          isLoaded={isLoaded}
          entity={entity}
          apiUrl={apiUrl}
          total={total}
          isFromPagination={isFromPagination}
          setIsFromPagination={setIsFromPagination}
        />
      )}
    </>
  );
}

export default TableAudit;

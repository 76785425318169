import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { SCHOOL_URL } from "../utils/endpoints";

export async function getSchools(
  id = null,
  provinceId = null,
  countyId = null
) {
  try {
    let apiURL = `${SCHOOL_URL}?PageSize=${PAGE_SIZE}`;
    if (id) {
      apiURL += `&${id}`;
    }
    if (provinceId) {
      apiURL += `&provinceId=${provinceId}`;
    }
    if (countyId) {
      apiURL += `&countyId=${countyId}`;
    }
    const response = await api.get(apiURL);
    const data = response.data.result;
    if (data.length > 0) {
      data[0] = {
        ...data[0],
        totalItems: response.data.totalItems,
      };
    }
    return data;
  } catch (error) {
    throw error;
  }
}

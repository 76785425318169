import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormEditTabInfo from "./FormsEdit/FormTabInfo";
import api from "../../utils/api";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { academicYearSchema } from "./academicYearSchema";
import { ACADEMIC_YEAR_URL } from "../../utils/endpoints";
import FormEditTabInfoResume from "./Forms/FormTabInfoResume";
import Alert from "../../components/Alert";
import SpecificationYear from "../../helpers/specificationYear";
import { getAcademicYearFormat } from "../../services/getAcademicYearFormat";
import { CrumbContext } from "../../provider/CrumbProvider";
import { formStyles } from "../../utils/styles";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../utils/constants";
import TableSchools from "./components/TableSchools";
import Search from "./components/Search";
import Filters from "./components/Filters";
import { AuthContext } from "../../provider/AuthProvider";

export default function EditAcademicYear() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [regexFormatYear, setRegexFormatYear] = useState([]);
  const [formatYear, setFormatYear] = useState("xxxx/xxxx");
  const { addToast } = Toast();
  const { crumb, setCrumb } = useContext(CrumbContext);
  const { id } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(academicYearSchema),
  });

  const year = watch("year");
  const yearFormat = watch("yearFormat");

  useEffect(() => {
    const getData = async () => {
      const response = await api.get(`${ACADEMIC_YEAR_URL}/${id}`);
      setSchools(response.data.academicYearForSchools);
      setFilteredSchools(response.data.academicYearForSchools);
      setValue("year", response.data.year);
      setValue("yearFormat", response.data.yearFormat);
      setCrumb({
        ...crumb,
        academicYear: response.data.year,
      });
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    if (yearFormat) {
      const getData = async () => {
        const responseFormatYear = await getAcademicYearFormat(yearFormat);
        setRegexFormatYear(
          responseFormatYear.filter((e) => e.value === yearFormat)[0].regex
        );
        setFormatYear(
          responseFormatYear
            .filter((e) => e.value === yearFormat)[0]
            .code.replaceAll("y", "9")
        );
      };
      getData();
    }
  }, [yearFormat]);

  const handleDeleteAcademicYear = async () => {
    api
      .delete(`${ACADEMIC_YEAR_URL}/${id}`)
      .then(() => {
        addToast({
          title: "Ano Lectivo excluído com sucesso",
          status: "success",
        });
        navigate("/anos-lectivos");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
        setIsOpen(false);
      });
  };

  const onSubmit = async (data) => {
    let validateYear = year?.replaceAll("_", "")?.split("/");
    if (
      !SpecificationYear.isSatisfiedBy({ year, regexFormatYear, validateYear })
    ) {
      return addToast({
        title: "Ano Lectivo inválido",
        status: "error",
      });
    }

    try {
      const res = await api.patch(`${ACADEMIC_YEAR_URL}/editAcademicYear`, {
        ...data,
        id,
      });

      if (res.status === 200) {
        navigate("/anos-lectivos");
        addToast({
          title: "Ano Lectivo actualizado com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      console.log(e);
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const [isToBeEdited, setIsToBeEdited] = useState(true);

  const handleSearch = () => {
    const filtered = schools.filter((school) => {
      const matchesName =
        inputValue === "" ||
        school.school.name.toLowerCase().includes(inputValue.toLowerCase());

      const matchesState =
        stateValue === "" || school.closed === stateValue.value;

      return matchesName && matchesState;
    });

    setFilteredSchools(filtered);
  };

  return (
    <Stack spacing={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isToBeEdited ? (
          <>
            <Heading as="h1" textTransform="uppercase" mb={5}>
              Resumo | {year}
            </Heading>
            <Box sx={formStyles} mb={5}>
              <FormEditTabInfoResume
                errors={errors}
                control={control}
                watch={watch}
                year={year}
              />
            </Box>
          </>
        ) : (
          <>
            <Heading as="h1" mb={5}>
              Editar {year}
            </Heading>

            <Box sx={formStyles} mb={5}>
              <FormEditTabInfo
                errors={errors}
                register={register}
                control={control}
                isToBeEdited={isToBeEdited}
                setValue={setValue}
                year={year}
                yearFormat={yearFormat}
                setFormatYear={setFormatYear}
                formatYear={formatYear}
              />
            </Box>
          </>
        )}

        <Box>
          <Box>
            {isToBeEdited ? (
              user.permissions.includes("EditAcademicYear") && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsToBeEdited(false);
                  }}
                >
                  Editar Ano Lectivo
                </Button>
              )
            ) : (
              <>
                <Button type="submit" mr={3}>
                  Guardar
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate("/anos-lectivos");
                  }}
                >
                  Cancelar
                </Button>
                <HStack mt={3}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Excluir
                  </Button>
                  <Tooltip
                    hasArrow
                    label="Esta acção exclui, de forma permanente, este ano lectivo do sistema"
                  >
                    <Flex alignItems="center" cursor="pointer">
                      <BsQuestionCircle />
                    </Flex>
                  </Tooltip>
                </HStack>
                <Alert
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  text={DIALOG_MESSAGE}
                  title="Excluir Ano Lectivo"
                  handleDelete={handleDeleteAcademicYear}
                  buttonText="Excluir"
                />
              </>
            )}
          </Box>
        </Box>
      </form>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={formStyles}
          px="0!important"
        >
          <Search
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSearch={handleSearch}
          />
          <Filters
            stateValue={stateValue}
            setStateValue={setStateValue}
            handleSearch={handleSearch}
          />
        </Box>
      </Box>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Lista de escolas
        </Heading>
        <TableSchools
          data={filteredSchools}
          setData={setFilteredSchools}
          isLoaded={isLoaded}
        />
      </Box>
    </Stack>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Heading,
  Stack,
  Button,
  Box,
  Input,
  Grid,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import TableItems from "./TableItems";
import Label from "../../components/Label";
import ModalAssociate from "./components/Modal";
import { lookUps } from ".";
import { useParams } from "react-router-dom";
import { PAGE_SIZE, handleEnterOnSearch } from "../../utils/constants";
import { formStyles } from "../../utils/styles";
import { MdAddCircle } from "react-icons/md";
import { ItemSchema } from "./ItemSchema";
import ModalArea from "./components/Modal/ModalArea";
import TableGeneralArea from "./TableGeneralArea";
import TableDetailedArea from "./TableDetailedArea";
import TableProvince from "./components/TableProvince";
import AreaFilters from "./components/Filters/AreaFilters";
import ModalProvince from "./components/Modal/ModalProvince";
import api from "../../utils/api";
import {
  COMMUNE_URL,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
  URBANDISTRICT_URL,
} from "../../utils/endpoints";
import ProvinceFilters from "./components/Filters/ProvinceFilters";
import TableSpecificArea from "./TableSpecificArea";

function ItemsPage() {
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(ItemSchema) });

  const province = watch("province");
  const county = watch("county");
  const natures = watch("natures");
  const type = watch("type");
  const inputSearch = watch("search");
  const [idItem, setIdItem] = useState(0);

  const [title, setTitle] = useState("Inserir novo item");
  const [buttonTitle, setButtonTitle] = useState("Inserir");

  const [dataTable, setDataTable] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenArea, setIsOpenArea] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [dataCommune, setDataCommune] = useState([]);
  const [dataUrbanDistrict, setDataUrbanDistrict] = useState([]);

  const [dataGeneralArea, setDataGeneralArea] = useState([]);
  const [dataSpecificArea, setDataSpecificArea] = useState([]);

  const { table } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await lookUps[table - 1].getFunction({
          OnlyActive: false,
          PageSize: 2500,
        });
        setDataTable(response);
        setOriginalDataTable(response);
        setIsLoaded(true);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, []);
  const [originalDataTable, setOriginalDataTable] = useState([]);

  const handleSearchCall = () => {
    if (typeof inputSearch === "undefined" || inputSearch === "") {
      return;
    }
    setOriginalDataTable(dataTable);
    const inputSearchLowerCase = inputSearch?.toLowerCase();
    const filtered = dataTable.filter((e) =>
      e.label.toLowerCase().includes(inputSearchLowerCase)
    );
    setDataTable(filtered);
  };

  const handleCleanFilters = async () => {
    setValue("search", undefined);
    const response = await lookUps[table - 1].getFunction({
      OnlyActive: false,
      PageSize: 2500,
    });
    setDataTable(response);
  };

  async function getDataCommune() {
    const response = await api.get(COMMUNE_URL);
    const data = response.data
      .slice()
      .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
      .sort((a, b) =>
        a.fromCounty.fromProvince.name.localeCompare(
          b.fromCounty.fromProvince.name
        )
      );
    setDataCommune(data);
  }

  async function getDataUrbanDistrict() {
    const response = await api.get(URBANDISTRICT_URL);
    const data = response.data
      .slice()
      .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
      .sort((a, b) =>
        a.fromCounty.fromProvince.name.localeCompare(
          b.fromCounty.fromProvince.name
        )
      );
    setDataUrbanDistrict(data);
  }

  async function getDataGeneralArea() {
    const response = await api.get(GENERAL_AREA_URL, {
      OnlyActive: false,
      PageSize: 2500,
    });
    const data = response.data;
    setDataGeneralArea(data);
  }

  async function getDataSpecificArea() {
    const response = await api.get(SPECIFIC_AREA_URL, {
      OnlyActive: false,
      PageSize: 2500,
    });
    const data = response.data;
    setDataSpecificArea(data);
  }

  useEffect(() => {
    if (
      lookUps[table - 1]?.hasMultiparent === true &&
      lookUps[table - 1]?.hasProvince === true
    ) {
      getDataCommune();
      getDataUrbanDistrict();
    }
    if (
      lookUps[table - 1]?.hasMultiparent === true &&
      lookUps[table - 1]?.hasProvince === false
    ) {
      getDataGeneralArea();
      getDataSpecificArea();
    }
  }, []);

  const renderTable = () => {
    if (
      lookUps[table - 1]?.hasMultiparent === true &&
      lookUps[table - 1]?.hasProvince === true
    ) {
      return (
        <TableProvince
          setButtonTitle={setButtonTitle}
          setValue={setValue}
          setTitle={setTitle}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
          setDataTable={setDataTable}
          dataTable={dataTable}
          dataCommune={dataCommune}
          dataUrbanDistrict={dataUrbanDistrict}
          setDataCommune={setDataCommune}
          setDataUrbanDistrict={setDataUrbanDistrict}
        />
      );
    } else if (lookUps[table - 1]?.hasMultiparent === true) {
      return (
        <Tabs>
          <TabList>
            <Tab>Área de Formação Geral</Tab>
            <Tab>Área de Formação Específica</Tab>
            <Tab>Área de Formação Detalhada</Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0} pt="20px">
              <TableGeneralArea
                setIdUpdateItem={setIdItem}
                setButtonTitle={setButtonTitle}
                setValue={setValue}
                setTitle={setTitle}
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
                dataGeneralArea={dataGeneralArea}
                setDataGeneralArea={setDataGeneralArea}
              />
            </TabPanel>
            <TabPanel p={0} pt="20px">
              <TableSpecificArea
                setIdUpdateItem={setIdItem}
                setButtonTitle={setButtonTitle}
                setValue={setValue}
                setTitle={setTitle}
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
                dataSpecificArea={dataSpecificArea}
                setDataSpecificArea={setDataSpecificArea}
              />
            </TabPanel>
            <TabPanel p={0} pt="20px">
              <TableDetailedArea
                setIdUpdateItem={setIdItem}
                setButtonTitle={setButtonTitle}
                setValue={setValue}
                setTitle={setTitle}
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
                setDataTable={setDataTable}
                dataTable={dataTable}
                hasProvince={lookUps[table - 1]?.hasProvince}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    } else {
      return (
        <TableItems
          setIdUpdateItem={setIdItem}
          setButtonTitle={setButtonTitle}
          setValue={setValue}
          setTitle={setTitle}
          isOpenUpdate={isOpen}
          setIsOpenUpdate={setIsOpen}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
          setDataTable={setDataTable}
          dataTable={dataTable}
        />
      );
    }
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Editar {lookUps[table - 1].name}</Heading>
      </Box>
      <Box>
        <Button
          rightIcon={<MdAddCircle />}
          onClick={() => {
            setTitle("Inserir novo item");
            setButtonTitle("Inserir");
            setIsOpen(true);
            setValue("name", "");
            setValue("code", "");
          }}
        >
          Inserir novo item
        </Button>
      </Box>

      <Box sx={formStyles} px="0!important">
        <Box px="20px">
          <Label title="Pesquisar pelo nome do item" />

          <Grid gridTemplateColumns="1fr auto">
            <Flex mr={4}>
              <Input
                {...register("search")}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onKeyDown={(e) => {
                  handleEnterOnSearch(e, handleSearchCall);
                }}
              />

              <Button
                onClick={() => {
                  handleSearchCall();
                }}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                Pesquisar
              </Button>
            </Flex>

            <Flex>
              <Button
                variant="link"
                onClick={() => {
                  handleCleanFilters();
                }}
              >
                Limpar consulta
              </Button>
            </Flex>
          </Grid>
        </Box>

        {lookUps[table - 1].entityName === "CourseDetailedArea" && (
          <AreaFilters setDataTable={setDataTable} setIsLoaded={setIsLoaded} />
        )}
        {lookUps[table - 1].entityName === "Province" && (
          <ProvinceFilters
            setDataCommune={setDataCommune}
            setDataUrbanDistrict={setDataUrbanDistrict}
            setIsLoaded={setIsLoaded}
          />
        )}
      </Box>

      {!lookUps[table - 1]?.hasMultiparent ? (
        <ModalAssociate
          setDataTable={setDataTable}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          title={title}
          register={register}
          watch={watch}
          buttonTitle={buttonTitle}
          control={control}
          errors={errors}
          ParentName={lookUps[table - 1]?.ParentName}
          idItem={idItem}
          handleSubmit={handleSubmit}
        />
      ) : !lookUps[table - 1]?.hasProvince ? (
        <ModalArea
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setDataTable={setDataTable}
          setDataGeneralArea={setDataGeneralArea}
          setDataSpecificArea={setDataSpecificArea}
        />
      ) : (
        <ModalProvince
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setDataCommune={setDataCommune}
          setDataUrbanDistrict={setDataUrbanDistrict}
        />
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Lista de Itens
        </Heading>
        {renderTable()}
      </Box>
    </Stack>
  );
}

export default ItemsPage;

/* eslint-disable no-self-compare */
/* eslint-disable no-unused-vars */
import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { FormTab1 } from "./FormTab1";
import { FormTab2 } from "./FormTab2";
import { FormTab3 } from "./FormTab3";
import { privateSchoolSchema, schoolSchema } from "./schoolSchema";
import { Toast } from "../../../components/Toast";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import {
  SCHOOL_URL,
  SCHOOL_URL_EDIT,
  UPDATE_MYSCHOOL,
} from "../../../utils/endpoints";
import { useContext, useEffect, useState } from "react";
import { formStyles } from "../../../utils/styles";
import FormButtons from "./FormButtons";
import { formatDate } from "../../../utils/formatDate";
import { FormTab4 } from "./FormTab4";
import { PRIVATE_NATURES } from "../../../utils/constants";
import { AuthContext } from "../../../provider/AuthProvider";
import { getLabType } from "../../../services/getLabType";

export function FormSchool({ isDisabled, setIsDisabled, id }) {
  const [activeTab, setActiveTab] = useState(0);
  const { addToast } = Toast();
  const navigate = useNavigate();
  const [showLabsInResume, setShowLabsInResume] = useState(false);
  const [isActive, setIsActive] = useState();
  const [hasLicenceValue, setHasLicenceValue] = useState("");
  const [diplomaTypeValue, setDiplomaTypeValue] = useState("");
  const [schooltype, setSchooltype] = useState("");
  const [labsData, setLabsData] = useState([]);
  const [labTypeData, setLabTypeData] = useState([]);
  const [navigationDisabled, setNavigationDisabled] = useState(true);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(
      schooltype && schooltype.label === PRIVATE_NATURES
        ? privateSchoolSchema
        : schoolSchema
    ),
  });

  const province = watch("province");
  const county = watch("county");
  const commune = watch("commune");
  const district = watch("district");
  const natures = watch("natures");
  const email = watch("email");
  const name = watch("name");
  const schoolAffiliation = watch("schoolAffiliation");

  useEffect(() => {
    if (!isDisabled && id) {
      setNavigationDisabled(false);
    }
  }, [isDisabled, id]);

  const onSubmit = (values, event) => {
    const data = getValues();
    const action = event.nativeEvent.submitter.name;

    if (
      data?.hasLicence?.value === false &&
      data?.reasonForAbsenceOfLicence === ""
    ) {
      addToast({
        title: "Obrigatório justificar motivo de não ter licença",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      (data?.alvara === undefined || data?.alvara === "")
    ) {
      addToast({
        title: "Obrigatório indicar número da licença",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      !/^\d{1,4}$/.test(data?.alvara)
    ) {
      addToast({
        title: "Formato do número da licença inválido",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      (data?.dateOfAlvara === undefined ||
        data?.dateOfAlvara === null ||
        data?.dateOfAlvara === "")
    ) {
      addToast({
        title: "Obrigatório indicar data da licença",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      validateDate(data?.dateOfAlvara)
    ) {
      addToast({
        title: "A data da licença não pode ser superior ao dia actual",
        status: "error",
      });
    } else if (validateDiploma(data)) {
      return;
    } else {
      if (action === "create") {
        setIsDisabled(true);
      } else if (action === "paginate") {
        setNavigationDisabled(false);
        setActiveTab(1);
      }
    }
  };

  const validateDate = (input) => {
    let inputDate = new Date(input);
    let today = new Date();

    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (inputDate > today) {
      return true;
    }

    return false;
  };

  const validateDiploma = (data) => {
    if (data?.natures.value !== 2) {
      if (data?.diplomaType?.value === undefined) {
        addToast({
          title: "Obrigatório indicar tipo de decreto",
          status: "error",
        });
        return true;
      }
      if (
        data?.diplomaType?.value === 3 &&
        data?.reasonForAbsenceOfDiploma === ""
      ) {
        addToast({
          title: "Obrigatório justificar motivo de não ter decreto",
          status: "error",
        });
        return true;
      }
      if (
        data?.diplomaType?.value !== 3 &&
        (data?.diploma === undefined || data?.diploma === "")
      ) {
        addToast({
          title: "Obrigatório indicar número do decreto",
          status: "error",
        });
        return true;
      }
      if (data?.diplomaType?.value !== 3 && !/^\d{1,4}$/.test(data?.diploma)) {
        addToast({
          title: "Formato do número do decreto inválido",
          status: "error",
        });
        return true;
      }
      if (
        data?.diplomaType?.value !== 3 &&
        (data?.diplomaCreationDate === undefined ||
          data?.diplomaCreationDate === null ||
          data?.diplomaCreationDate === "")
      ) {
        addToast({
          title: "Obrigatório indicar data do diploma",
          status: "error",
        });
        return true;
      }
      if (
        data?.diplomaType?.value !== 3 &&
        validateDate(data?.diplomaCreationDate)
      ) {
        addToast({
          title: "A data do diploma não pode ser superior ao dia actual",
          status: "error",
        });
        return true;
      }
    } else {
      return false;
    }
  };

  function createSchool() {
    const data = getValues();

    api
      .post(SCHOOL_URL, {
        name: data.name ? data.name : null,
        code: data.code ? data.code : null,
        email: data.email ? data.email : null,
        natureId: data.natures ? data.natures.value : null,
        creationYear: data.yearOfCreate ? Number(data.yearOfCreate) : null,
        diplomaType: data.diplomaType
          ? data.diplomaType.value.toString()
          : null,
        creationDiploma: data.diplomaType
          ? data.diplomaType.value !== 3
            ? data.diploma
            : null
          : null,
        diplomaCreationDate: data.diplomaType
          ? data.diplomaType.value !== 3
            ? data.diplomaCreationDate
            : null
          : null,
        reasonForAbsenceOfDiploma: data.diplomaType
          ? data.diplomaType.value !== 3
            ? null
            : data.reasonForAbsenceOfDiploma
          : null,
        hasLicence: data.hasLicence ? data.hasLicence.value : null,
        reasonForAbsenceOfLicence: data.hasLicence
          ? data.hasLicence.value === true
            ? null
            : data.reasonForAbsenceOfLicence
          : null,
        schoolLicence: data.alvara ? data.alvara : null,
        licenceDate: data.hasLicence
          ? data.hasLicence.value === true
            ? data.dateOfAlvara
            : null
          : null,
        schoolLicenceFullName: data.licence ? data.licence : null,
        typologyId: data.tipology ? data.tipology.value : null,
        typeId: data.types ? data.types.value : null,
        fromCountyId: data.county ? data.county.value : null,
        schoolAffiliationIds: data.schoolAffiliation
          ? data.schoolAffiliation.map((school) => school.value)
          : null,
        fromCommuneId: data.commune ? data.commune.value : null,
        address: data.address ? data.address : null,
        pointOfReference: data.pointOfReference ? data.pointOfReference : null,
        locality: data.locality ? data.locality : null,
        fromUrbanDistrictId: data.district ? data.district.value : null,
        schoolContactList: {
          phone: data.phone ? data.phone : null,
          directorName: data.directorName ? data.directorName : null,
          directorPhone: data.directorPhone ? data.directorPhone : null,
          directorEmail: data.directorEmail ? data.directorEmail : null,
          directorAdminName: data.directorAdminName
            ? data.directorAdminName
            : null,
          directorAdminPhone: data.directorAdminPhone
            ? data.directorAdminPhone
            : null,
          directorAdminEmail: data.directorAdminEmail
            ? data.directorAdminEmail
            : null,
          directorPedName: data.directorPedName ? data.directorPedName : null,
          directorPedPhone: data.directorPedPhone
            ? data.directorPedPhone
            : null,
          directorPedEmail: data.directorPedEmail
            ? data.directorPedEmail
            : null,
          entityName: data.entityName ? data.entityName : null,
          entityPhone: data.entityPhone ? data.entityPhone : null,
          entityEmail: data.entityEmail ? data.entityEmail : null,
          website: data.website ? data.website : null,
        },

        latitude: data.latitude ? data.latitude : null,
        longitude: data.longitude ? data.longitude : null,
      })
      .then((response) => {
        const res = response.data;

        addToast({
          title: "Escola cadastrada com sucesso",
          status: "success",
        });
        navigate("/escolas");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  }

  function patchSchool() {
    const data = getValues();

    if (
      (data?.hasLicence?.value === false &&
        data?.reasonForAbsenceOfLicence === null) ||
      data?.reasonForAbsenceOfLicence === ""
    ) {
      addToast({
        title: "Obrigatório justificar motivo de não ter licença",
        status: "error",
      });
    } else if (
      (data?.hasLicence?.value === true && data?.alvara === null) ||
      data?.alvara === ""
    ) {
      addToast({
        title: "Obrigatório indicar número da licença",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      !/^\d{1,4}$/.test(data?.alvara)
    ) {
      addToast({
        title: "Formato do número da licença inválido",
        status: "error",
      });
    } else if (
      (data?.hasLicence?.value === true && data?.dateOfAlvara === null) ||
      data?.dateOfAlvara === ""
    ) {
      addToast({
        title: "Obrigatório indicar data da licença",
        status: "error",
      });
    } else if (
      data?.hasLicence?.value === true &&
      validateDate(data?.dateOfAlvara)
    ) {
      addToast({
        title: "A data da licença não pode ser superior ao dia actual",
        status: "error",
      });
    } else {
      const apiURL = auth.isSchool ? UPDATE_MYSCHOOL : SCHOOL_URL_EDIT;
      api
        .patch(apiURL, {
          name: data.name ? data.name : null,
          code: data.code ? data.code : null,
          email: data.email ? data.email : null,
          natureId: data.natures ? data.natures.value : null,
          creationYear: data.yearOfCreate ? Number(data.yearOfCreate) : null,
          diplomaType: data.diplomaType
            ? data.diplomaType.value.toString()
            : null,
          creationDiploma: data.diplomaType
            ? data.diplomaType.value !== 3
              ? data.diploma
              : null
            : null,
          diplomaCreationDate: data.diplomaType
            ? data.diplomaType.value !== 3
              ? data.diplomaCreationDate
              : null
            : null,
          reasonForAbsenceOfDiploma: data.diplomaType
            ? data.diplomaType.value !== 3
              ? null
              : data.reasonForAbsenceOfDiploma
            : null,
          hasLicence: data.hasLicence ? data.hasLicence.value : null,
          reasonForAbsenceOfLicence: data.hasLicence
            ? data.hasLicence.value === true
              ? null
              : data.reasonForAbsenceOfLicence
            : null,
          schoolLicence: data.alvara ? data.alvara : null,
          licenceDate: data.hasLicence
            ? data.hasLicence.value === true
              ? data.dateOfAlvara
              : null
            : null,
          schoolLicenceFullName: data.licence ? data.licence : null,
          typologyId: data.tipology ? data.tipology.value : null,
          typeId: data.types ? data.types.value : null,
          fromCountyId: data.county ? data.county.value : null,
          schoolAffiliationIds: data.schoolAffiliation
            ? data.schoolAffiliation.map((school) => school.value)
            : null,
          fromCommuneId: data.commune ? data.commune.value : null,
          address: data.address ? data.address : null,
          pointOfReference: data.pointOfReference
            ? data.pointOfReference
            : null,
          locality: data.locality ? data.locality : null,
          fromUrbanDistrictId: data.district ? data.district.value : null,
          schoolContactList: {
            phone: data.phone ? data.phone : null,
            directorName: data.directorName ? data.directorName : null,
            directorPhone: data.directorPhone ? data.directorPhone : null,
            directorEmail: data.directorEmail ? data.directorEmail : null,
            directorAdminName: data.directorAdminName
              ? data.directorAdminName
              : null,
            directorAdminPhone: data.directorAdminPhone
              ? data.directorAdminPhone
              : null,
            directorAdminEmail: data.directorAdminEmail
              ? data.directorAdminEmail
              : null,
            directorPedName: data.directorPedName ? data.directorPedName : null,
            directorPedPhone: data.directorPedPhone
              ? data.directorPedPhone
              : null,
            directorPedEmail: data.directorPedEmail
              ? data.directorPedEmail
              : null,
            entityName: data.entityName ? data.entityName : null,
            entityPhone: data.entityPhone ? data.entityPhone : null,
            entityEmail: data.entityEmail ? data.entityEmail : null,
            website: data.website ? data.website : null,
          },
          latitude: data.latitude ? data.latitude : null,
          longitude: data.longitude ? data.longitude : null,
          schoolId: id,
        })
        .then(() => {
          api
            .patch("/api/School/AddLabsToSchool", {
              id: id,
              labs: labsData,
            })
            .then(() => {
              addToast({
                title: "Escola actualizada com sucesso",
                status: "success",
              });
              if (auth.isSchool) {
                navigate(`/escolas/gerir/${id}`);
              } else {
                navigate("/escolas");
              }
            })
            .catch((error) => {
              let errors = error.response.data.errors;
              for (let err in errors) {
                addToast({ title: errors[err], status: "error" });
              }
            });
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          for (let err in errors) {
            addToast({ title: errors[err], status: "error" });
          }
        });
    }
  }

  const handleTabChange = (index) => {
    if (!navigationDisabled) {
      setActiveTab(index);
    }
  };

  useEffect(() => {
    const getLabTypeData = async () => {
      const response = await getLabType();
      setLabTypeData(response);
    };

    getLabTypeData();
  }, []);

  useEffect(
    () => {
      if (id && labTypeData.length > 0) {
        const apiURL = auth.isSchool
          ? `/api/School/userSchool?Id=${user.id}`
          : `${SCHOOL_URL}/${id}`;

        api.get(apiURL).then((response) => {
          const data = response.data;

          setIsActive(data.isActive);

          if (data?.schoolLabsList !== null) {
            setShowLabsInResume(true);
          }

          const licenceDatFormat =
            data && data.licenceDate === null
              ? null
              : typeof data.licenceDate === "string"
              ? data.licenceDate.split("T")
              : null;

          const dataFormatada = formatDate(
            licenceDatFormat ? licenceDatFormat[0] : null
          );

          setValue("name", data?.name);
          setValue("code", data?.code);
          setValue("email", data?.email);
          setValue("natures", {
            value: data.nature?.id,
            label: data.nature?.name,
          });

          setValue("tipology", {
            value: data.typology?.id,
            label: data.typology?.name,
          });
          setValue("types", {
            value: data.type?.id,
            label: data.type?.name,
          });

          setValue("province", {
            value: data.fromCounty?.fromProvince.id,
            label: data.fromCounty?.fromProvince.name,
          });

          setValue("county", {
            value: data?.fromCounty.id,
            label: data?.fromCounty.name,
          });

          setValue("commune", {
            value: data?.fromCommune?.id,
            label: data?.fromCommune?.name,
          });

          setValue("district", {
            value: data?.fromUrbanDistrict?.id,
            label: data?.fromUrbanDistrict?.name,
          });

          setValue(
            "schoolAffiliation",
            data?.schoolAffiliation.map((school) => ({
              value: school.id,
              label: school.name,
            }))
          );

          setValue(
            "yearOfCreate",
            data?.creationYear && data?.creationYear?.toString()
          );
          setDiplomaTypeValue(data?.diplomaType);
          setValue("diploma", data?.creationDiploma);
          setValue("diplomaCreationDate", data?.diplomaCreationDate);
          setValue(
            "reasonForAbsenceOfDiploma",
            data?.reasonForAbsenceOfDiploma
          );
          setHasLicenceValue(data?.hasLicence);
          setValue(
            "reasonForAbsenceOfLicence",
            data?.reasonForAbsenceOfLicence
          );
          setValue("alvara", data?.schoolLicence);
          setValue("dateOfAlvara", dataFormatada);

          setValue("address", data?.address);
          setValue("pointOfReference", data?.pointOfReference);
          setValue("locality", data?.locality);
          setValue("longitude", data?.longitude);
          setValue("latitude", data?.latitude);

          setValue("phone", data?.schoolContactList?.phone);
          setValue("directorName", data.schoolContactList?.directorName);
          setValue("directorPhone", data.schoolContactList?.directorPhone);
          setValue("directorEmail", data.schoolContactList?.directorEmail);

          setValue(
            "directorAdminName",
            data.schoolContactList?.directorAdminName
          );
          setValue(
            "directorAdminPhone",
            data.schoolContactList?.directorAdminPhone
          );
          setValue(
            "directorAdminEmail",
            data.schoolContactList?.directorAdminEmail
          );

          setValue("directorPedName", data.schoolContactList?.directorPedName);
          setValue(
            "directorPedPhone",
            data.schoolContactList?.directorPedPhone
          );
          setValue(
            "directorPedEmail",
            data.schoolContactList?.directorPedEmail
          );

          setValue("entityName", data.schoolContactList?.entityName);
          setValue("entityPhone", data.schoolContactList?.entityPhone);
          setValue("entityEmail", data.schoolContactList?.entityEmail);

          setValue("website", data.schoolContactList?.website);

          if (data?.labs.length > 0) {
            setLabsData(
              data?.labs.map((item) => ({
                labType: item.labType.id,
                labName: item.labType.name,
                numberOfAvailableLabs: item.numberOfAvailableLabs,
                numberOfUnavailableLabs: item.numberOfUnavailableLabs,
                numberOfLabsTotal: item.numberOfLabsTotal,
              }))
            );
          } else {
            setLabsData([
              {
                labType: labTypeData[0].value,
                labName: labTypeData[0].label,
                numberOfAvailableLabs: 0,
                numberOfUnavailableLabs: 0,
                numberOfLabsTotal: 0,
              },
            ]);
          }
        });
      }
    },
    // eslint-disable-next-line
    [id, labTypeData]
  );

  const deleteSchool = () => {
    api
      .delete(`${SCHOOL_URL}/${id}`)
      .then(() => {
        addToast({
          title: "Escola excluída com sucesso",
          status: "success",
        });
        navigate("/escolas");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  useEffect(() => {
    if (errors.latitude) {
      addToast({ title: errors.latitude.message, status: "error" });
    }
    if (errors.longitude) {
      addToast({ title: errors.longitude.message, status: "error" });
    }
  }, [errors]);

  return (
    <>
      <Heading mb={4}>
        {isDisabled
          ? `Resumo | ${name}`
          : id
          ? `Editar ${name}`
          : "Cadastrar Escola"}
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        {isDisabled ? (
          <Box sx={formStyles}>
            <Stack spacing={5}>
              <>
                <Heading as="h2" fontSize="md" py="4" textTransform="uppercase">
                  Informação Institucional
                </Heading>

                <FormTab1
                  province={province}
                  errors={errors}
                  register={register}
                  control={control}
                  natures={natures}
                  isDisabled={isDisabled}
                  setValue={setValue}
                  watch={watch}
                  mode="resume"
                  schooltype={schooltype}
                  setSchooltype={setSchooltype}
                  schoolAffiliation={schoolAffiliation}
                  hasLicenceValue={hasLicenceValue}
                  diplomaTypeValue={diplomaTypeValue}
                />
              </>

              <>
                <Heading as="h2" fontSize="md" py="4" textTransform="uppercase">
                  Localização Geográfica
                </Heading>

                <FormTab2
                  register={register}
                  control={control}
                  province={province}
                  isDisabled={isDisabled}
                  county={county}
                  commune={commune}
                  district={district}
                />
              </>

              <>
                <Heading as="h2" fontSize="md" py="4" textTransform="uppercase">
                  Contactos
                </Heading>

                <FormTab3
                  register={register}
                  isDisabled={isDisabled}
                  email={email}
                />
              </>

              {showLabsInResume && (
                <>
                  <Heading
                    as="h2"
                    fontSize="md"
                    py="4"
                    textTransform="uppercase"
                  >
                    Infra-estruturas
                  </Heading>

                  <FormTab4
                    labsData={labsData}
                    setLabsData={setLabsData}
                    isDisabled={isDisabled}
                  />
                </>
              )}
            </Stack>
          </Box>
        ) : (
          <>
            <Tabs mt="6" index={activeTab} onChange={handleTabChange}>
              <TabList>
                <Tab>Informação Institucional</Tab>
                <Tab>Localização Geográfica</Tab>
                <Tab>Contactos</Tab>
                {id ? <Tab>Infra-estruturas</Tab> : null}
              </TabList>

              <TabPanels>
                <TabPanel p={0} pt="20px">
                  <Heading
                    as="h2"
                    fontSize="md"
                    textTransform="uppercase"
                    pb={2}
                  >
                    Informação Institucional
                  </Heading>

                  <Box sx={formStyles} mb={5}>
                    <FormTab1
                      province={province}
                      errors={errors}
                      register={register}
                      control={control}
                      natures={natures}
                      isDisabled={isDisabled}
                      watch={watch}
                      setValue={setValue}
                      mode="create"
                      schooltype={schooltype}
                      setSchooltype={setSchooltype}
                      schoolAffiliation={schoolAffiliation}
                      hasLicenceValue={hasLicenceValue}
                      diplomaTypeValue={diplomaTypeValue}
                    />
                  </Box>
                </TabPanel>

                <TabPanel p={0} pt="20px">
                  <Heading
                    as="h2"
                    fontSize="md"
                    textTransform="uppercase"
                    pb={2}
                  >
                    Localização Geográfica
                  </Heading>

                  <Box sx={formStyles} mb={5}>
                    <FormTab2
                      register={register}
                      control={control}
                      province={province}
                      county={county}
                      isDisabled={isDisabled}
                      commune={commune}
                      district={district}
                    />
                  </Box>
                </TabPanel>

                <TabPanel p={0} pt="20px">
                  <Heading
                    as="h2"
                    fontSize="md"
                    textTransform="uppercase"
                    pb={2}
                  >
                    Contactos
                  </Heading>

                  <Box sx={formStyles} mb={5}>
                    <FormTab3
                      register={register}
                      email={email}
                      isDisabled={isDisabled}
                    />
                  </Box>
                </TabPanel>

                {id ? (
                  <TabPanel p={0} pt="20px">
                    <Heading
                      as="h2"
                      fontSize="md"
                      textTransform="uppercase"
                      pb={2}
                    >
                      Infra-estruturas
                    </Heading>

                    <Box mb={5}>
                      <FormTab4
                        labsData={labsData}
                        setLabsData={setLabsData}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  </TabPanel>
                ) : null}
              </TabPanels>
            </Tabs>
          </>
        )}

        <FormButtons
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          submitSchool={createSchool}
          patchSchool={patchSchool}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          deleteSchool={deleteSchool}
          isActive={isActive}
        />
      </form>
    </>
  );
}

import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
import { REQUIRED_FIELD } from "../../../utils/constants";

export default function FormTabInfoResume({ errors, control, year }) {
  const [academicYearFormatData, setAcademicYearFormatData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYearFormat();
      setAcademicYearFormatData(response);
    };
    getData();
  }, []);

  const handleLabelInAcademicYear = (year) => {
    const label = year.includes("/")
      ? "4 + 4 dígitos - xxxx/xxxx"
      : "4 dígitos - xxxx";
    return label;
  };

  return (
    <>
      <Stack spacing={5}>
        <FormControl isInvalid={!!errors.name} width="70%">
          <Label title="Formato" isRequired={true} />
          <Controller
            control={control}
            name="yearFormat"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                isDisabled={true}
                value={Number(value)}
                onChange={onChange}
              >
                <Stack direction="column">
                  {academicYearFormatData.map((e) => {
                    return (
                      <Radio value={e.value}>
                        {handleLabelInAcademicYear(e.code)}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>
            {errors.yearFormat && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>

        <HStack width="200px">
          <FormControl isInvalid={!!errors.educationLevel}>
            <Label title="Ano Lectivo" isRequired={true} />
            <Input
              value={year}
              isDisabled
              placeholder="____/____"
              type="text"
            />
            <FormErrorMessage>
              {errors.year && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}

import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import TabStudentInformation from "./TabStudentInformation";
import TabSchoolData from "./TabSchoolData";
import FormButtons from "./FormButtons";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import { AuthContext } from "../../../provider/AuthProvider";

export default function Form({
  isDisabled,
  setIsDisabled,
  id,
  register,
  handleSubmit,
  errors,
  control,
  watch,
  setValue,
  unregister,
  createStudent,
  schoolBondRequests,
  setSchoolBondRequests,
  schoolBondRequestsData,
  setSchoolBondRequestsData,
  isActive,
  editStudent,
  cancelEdit,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { addToast } = Toast();
  const [navigationDisabled, setNavigationDisabled] = useState(true);
  const [userCannotEdit, setUserCannotEdit] = useState(false);
  const [fieldIsDisabled, setFieldIsDisabled] = useState(false);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const name = watch("name");
  const birthDate = watch("birthDate");

  useEffect(() => {
    if (user.schoolId && schoolBondRequestsData.length > 0) {
      const schoolBondedId =
        schoolBondRequestsData[schoolBondRequestsData.length - 1].schoolBonded
          .id;
      if (user.schoolId !== schoolBondedId) {
        setUserCannotEdit(true);
      }
      if (schoolBondRequestsData.some((bond) => !bond.isEditable)) {
        setFieldIsDisabled(true);
      }
    }
  }, [schoolBondRequestsData]);

  useEffect(() => {
    if (!isDisabled && id) {
      setNavigationDisabled(false);
    }
  }, [isDisabled, id]);

  const handleTabChange = (index) => {
    if (!navigationDisabled) {
      setActiveTab(index);
    }
  };

  const checkBirthDate = (courseEducationalCycleBondedId) => {
    const cycleId = courseEducationalCycleBondedId;
    const inputDate = new Date(birthDate);
    const currentDate = new Date();

    const ageInMilliseconds = currentDate - inputDate;
    const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

    if (
      (cycleId === 5 && ageInYears < 12) ||
      (cycleId === 6 && ageInYears < 15)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (values, event) => {
    const action = event.nativeEvent.submitter.name;

    if (action === "paginate") {
      setNavigationDisabled(false);
      setActiveTab(1);
      return true;
    }

    if (!schoolBondRequests.length > 0) {
      addToast({
        title: "Obrigatório inserir Dados Escolares",
        status: "error",
      });
    } else if (
      checkBirthDate(schoolBondRequests[0].courseEducationalCycleBondedId)
    ) {
      if (schoolBondRequests[0].courseEducationalCycleBondedId === 5) {
        handleTabChange(0);
        addToast({
          title: "O aluno deve ter pelo menos 12 anos de idade",
          status: "error",
        });
      } else if (schoolBondRequests[0].courseEducationalCycleBondedId === 6) {
        handleTabChange(0);
        addToast({
          title: "O aluno deve ter pelo menos 15 anos de idade",
          status: "error",
        });
      }
    } else {
      if (action === "create") {
        setIsDisabled(true);
        if (id) {
          editStudent();
        }
      }
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Heading as="h1">
          {isDisabled
            ? `Resumo | ${name}`
            : id
            ? `Editar ${name}`
            : `Cadastrar Aluno`}
        </Heading>
        {isDisabled ? (
          <Stack gap={5}>
            <TabStudentInformation
              isDisabled={isDisabled}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
              unregister={unregister}
            />
            <TabSchoolData
              isDisabled={isDisabled}
              id={id}
              schoolBondRequestsData={schoolBondRequestsData}
            />
          </Stack>
        ) : (
          <>
            <Tabs index={activeTab} onChange={handleTabChange}>
              <TabList>
                <Tab>Informações do Aluno</Tab>
                <Tab>Dados Escolares</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} pt="20px">
                  <TabStudentInformation
                    isDisabled={isDisabled || userCannotEdit}
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    unregister={unregister}
                    handleTabChange={handleTabChange}
                    fieldIsDisabled={fieldIsDisabled}
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabSchoolData
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    schoolBondRequests={schoolBondRequests}
                    setSchoolBondRequests={setSchoolBondRequests}
                    schoolBondRequestsData={schoolBondRequestsData}
                    setSchoolBondRequestsData={setSchoolBondRequestsData}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
        <FormButtons
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          createStudent={createStudent}
          cancelEdit={cancelEdit}
          isActive={isActive}
        />
      </Stack>
    </Box>
  );
}

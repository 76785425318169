import { Flex, Image, Heading, Spacer, Box } from "@chakra-ui/react";
import {
  MdHome,
  MdCalendarMonth,
  MdPerson,
  MdStar,
  MdWork,
  MdSchool,
  MdMenuBook,
  MdModeEdit,
  MdSettings,
  MdHolidayVillage,
  MdScreenSearchDesktop,
  MdTableChart,
} from "react-icons/md";
import MenuItem from "./MenuItem";
import Logout from "../../components/Logout/";
import { useContext } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import { borderRadius } from "../../utils/styles";

function Menu() {
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="center" mb={5}>
        <Image
          src="/images/logo.png"
          alt="Sistema de Informação para o Ensino Técnico-Profissional"
        />
      </Flex>
      <MenuItem title="Início" link="/inicio" icon={<MdHome color="#000" />} />
      <Heading
        fontSize="14px"
        textTransform="uppercase"
        ms="10px"
        mt={5}
        mb={1}
      >
        Menu
      </Heading>
      {user.permissions.includes("GetSchool") && (
        <MenuItem
          title="Escolas"
          link="/escolas"
          icon={<MdHolidayVillage color="#000" />}
        />
      )}
      {user.permissions.includes("GetCourses") && (
        <MenuItem
          title="Cursos"
          link="/cursos"
          icon={<MdMenuBook color="#000" />}
        />
      )}
      {user.permissions.includes("GetTeachers") && (
        <MenuItem
          title="Docentes"
          link="/docentes"
          icon={<MdWork color="#000" />}
        />
      )}
      {user.permissions.includes("GetStudents") && (
        <MenuItem
          title="Alunos"
          link="/alunos"
          icon={<MdSchool color="#000" />}
        />
      )}
      {user.permissions.includes("GetReports") && (
        <MenuItem
          title="Estatísticas"
          link="/estatisticas"
          icon={<MdTableChart color="#000" />}
        />
      )}
      {user.permissions.includes("GetRoles") && (
        <MenuItem
          title="Perfis"
          link="/perfis"
          icon={<MdStar color="#000" />}
        />
      )}
      {user.permissions.includes("GetUser") && (
        <MenuItem
          title="Utilizadores"
          link="/utilizadores"
          icon={<MdPerson color="#000" />}
        />
      )}
      {(user.permissions.includes("ManageLookups") ||
        user.permissions.includes("ManageLookupsNational")) && (
        <MenuItem
          title="Configurações"
          link="/configuracoes"
          icon={<MdSettings color="#000" />}
        />
      )}
      {user.permissions.includes("GetAcademicYears") && (
        <MenuItem
          title="Anos Lectivos"
          link="/anos-lectivos"
          icon={<MdCalendarMonth color="#000" />}
        />
      )}
      {user.permissions.includes("GetAudit") && (
        <MenuItem
          title="Auditoria"
          link="/auditoria"
          icon={<MdScreenSearchDesktop color="#000" />}
        />
      )}
      <Heading
        fontSize="14px"
        textTransform="uppercase"
        ms="10px"
        mt={5}
        mb={1}
      >
        Utilizador
      </Heading>
      <MenuItem
        title="Editar conta"
        link="/editar-conta"
        icon={<MdModeEdit color="#000" />}
      />
      <Logout />
      <Spacer />
      <Flex justifyContent="center" mt={3}>
        <Box bg="#fff" w="80%" padding="4px 8px" borderRadius={borderRadius}>
          <Image src="/images/med.svg" alt="Ministério da Educação de Angola" />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Menu;

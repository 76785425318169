import { useMemo, useEffect, useState, useContext } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getAcademicYear } from "../../../../services/getAcademicYear";
import Table from "../../../../components/Table";
import api from "../../../../utils/api";
import {
  GET_MY_SCHOOL,
  SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_MY_SCHOOL,
  SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_SCHOOL,
  SCHOOL_URL,
} from "../../../../utils/endpoints";
import ErrorToCloseAcademicYear from "./ErrorsAcademicYear";
import { AuthContext } from "../../../../provider/AuthProvider";
import { Toast } from "../../../../components/Toast";
import Alert from "../../../../components/Alert";
import { DIALOG_MESSAGE } from "../../../../utils/constants";

export default function TableCloseAcademicYear({ setDataTable, dataTable }) {
  const [isLoaded, setIsLoded] = useState(false);
  const [showErroCloseAcademicYear, setShowErroCloseAcademicYear] =
    useState(false);
  const [dataError, setDataError] = useState([]);
  const { addToast } = Toast();
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const user = auth;
  const [academicYear, setAcademicYear] = useState(null);
  const [academicYearTitle, setAcademicYearTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id) {
      let apiUrl =
        user.isSchool === true ? GET_MY_SCHOOL : `${SCHOOL_URL}/${id}`;

      api.get(apiUrl).then((response) => {
        const { academicYears } = response.data;
        setDataTable(academicYears);
        setIsLoded(true);
      });
    }
  }, []);

  async function closeAcademicYear() {
    try {
      let apiUrl =
        user.isSchool === true
          ? SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_MY_SCHOOL
          : SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_SCHOOL;

      const response = await api.patch(apiUrl, {
        schoolId: id,
        academicYearId: academicYear.id,
      });

      if (response.data && response.data.success === false) {
        setShowErroCloseAcademicYear(true);
        setAcademicYearTitle(academicYear.title);
        setDataError(response.data);
      } else {
        addToast({
          title: "Ano Lectivo encerrado com sucesso",
          status: "success",
        });
        let apiUrl =
          user.isSchool === true ? GET_MY_SCHOOL : `${SCHOOL_URL}/${id}`;
        api.get(apiUrl).then((response) => {
          const { academicYears } = response.data;
          setDataTable(academicYears);
        });
        setIsOpen(false);
        setShowErroCloseAcademicYear(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Ano Lectivo",
        accessor: "academicYear.year",
      },
      {
        Header: "Estado",
        accessor: (item) => {
          if (!item.closed) {
            return item.academicYear.closeable ? "A encerrar" : "A decorrer";
          }
          return "Encerrado";
        },
        Cell: (item) => {
          return !item.row.original.closed ? (
            item.row.original.academicYear.closeable ? (
              <Text>A encerrar</Text>
            ) : (
              <Text>A decorrer</Text>
            )
          ) : (
            <Text>Encerrado</Text>
          );
        },
        sortType: (rowA, rowB) => {
          const estadoA = !rowA.original.closed
            ? rowA.original.academicYear.closeable
              ? "A encerrar"
              : "A decorrer"
            : "Encerrado";
          const estadoB = !rowB.original.closed
            ? rowB.original.academicYear.closeable
              ? "A encerrar"
              : "A decorrer"
            : "Encerrado";

          return estadoA.localeCompare(estadoB);
        },
      },
      {
        Header: "Encerrar",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <>
              {user.isSchool === true &&
              user.user.permissions.includes("CloseAcademicYearForMySchool") ? (
                <Button
                  onClick={() => {
                    setIsOpen(true);
                    setAcademicYear(item.row.original.academicYear);
                  }}
                  isDisabled={item.row.original.closed === true ? true : false}
                >
                  Encerrar
                </Button>
              ) : (
                <>
                  {user.user.permissions.includes(
                    "CloseAcademicYearForSchools"
                  ) && (
                    <Button
                      onClick={() => {
                        setIsOpen(true);
                        setAcademicYear(item.row.original.academicYear);
                      }}
                      isDisabled={
                        item.row.original.academicYear.closeable === false
                          ? true
                          : item.row.original.closed
                          ? true
                          : false
                      }
                    >
                      Encerrar
                    </Button>
                  )}
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {showErroCloseAcademicYear ? (
        <Heading>Encerrar Ano Lectivo {academicYearTitle}</Heading>
      ) : (
        <Heading>Anos Lectivos</Heading>
      )}

      {showErroCloseAcademicYear ? (
        <ErrorToCloseAcademicYear data={dataError} />
      ) : (
        <>
          <Table
            setIsLoaded={setIsLoded}
            getFunction={getAcademicYear}
            setData={setDataTable}
            columns={columns}
            data={dataTable}
            isLoaded={isLoaded}
          />
          <Alert
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            text={DIALOG_MESSAGE}
            title="Encerrar Ano Lectivo"
            handleDelete={closeAcademicYear}
            buttonText="Encerrar"
          />
        </>
      )}
    </>
  );
}

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import Select from "react-select";
import { borderColor } from "../../../utils/colors";
import { selectStyles } from "../../../utils/styles";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

function Filters({ stateValue, setStateValue, handleSearch }) {
  const stateOptions = [
    {
      value: false,
      label: "Por encerrar",
    },
    { value: true, label: "Encerrado" },
  ];

  const clearFilters = () => {
    setStateValue("");
    handleSearch();
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar lista de escolas
          </Text>
        </Box>
      </Box>
      <Box px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Estado" />
                <Select
                  placeholder="Seleccionar"
                  options={stateOptions}
                  onChange={(option) => {
                    setStateValue(option);
                  }}
                  value={stateValue || ""}
                  styles={selectStyles}
                  isClearable={true}
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Flex alignSelf="end">
            <Button mr={4} onClick={handleSearch}>
              Filtrar
            </Button>
            <Button variant="link" onClick={clearFilters}>
              Limpar filtros
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Filters;

import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Table from "../../../../components/Table";

function Details({ details, data }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: "Código / Identificação",
        accessor: (row) => row.code || row.documentNumber,
        disableSortBy: true,
      },
      {
        Header: "Nome / Designação",
        accessor: "name",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <Center
        _hover={{
          cursor: "pointer",
        }}
      >
        <MdOutlineRemoveRedEye onClick={onOpen} />
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes: {details}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={1}>
              <Table data={data} columns={columns} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Details;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";
import api from "../../../utils/api";
import { DELETE_STUDENT_URL } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../components/Toast";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function DeleteStudent({ id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const deleteStudent = () => {
    api
      .delete(DELETE_STUDENT_URL, {
        data: { ids: [id] },
      })
      .then(() => {
        addToast({
          title: "Aluno excluído com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/alunos");
        } else {
          navigate(`/gerir/${user?.schoolId}/alunos`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
        onClose();
      });
  };

  return (
    <HStack>
      <Button variant="secondary" onClick={onOpen}>
        Excluir
      </Button>
      <Tooltip
        hasArrow
        label="Esta acção exclui, de forma permanente, este aluno do sistema"
      >
        <Flex alignItems="center">
          <BsQuestionCircle />
        </Flex>
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir Aluno
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button onClick={deleteStudent}>Excluir</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
}
export default DeleteStudent;

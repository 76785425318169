import FormAcademicYear from "./FormAcademicYear";
import { useState } from "react";

function NewAcademicYear() {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <FormAcademicYear isDisabled={isDisabled} setIsDisabled={setIsDisabled} />
  );
}

export default NewAcademicYear;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../../components/Label";
import api from "../../../../utils/api";
import { UPDATE_ITEM_LOOKUP } from "../../../../utils/endpoints";
import { Toast } from "../../../../components/Toast";
import { lookUps } from "../..";
import { useParams } from "react-router-dom";
import { REQUIRED_FIELD } from "../../../../utils/constants";
import { ItemSchemForDetail } from "../../ItemSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { EditIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

export default function ModalAreaEdit({ item, setDataTable }) {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(ItemSchemForDetail) });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { addToast } = Toast();

  useEffect(() => {
    setValue("code_general", item.row.original.specificArea.generalArea.code);
    setValue("name_general", item.row.original.specificArea.generalArea.name);
    setValue("code_specific", item.row.original.specificArea.code);
    setValue("name_specific", item.row.original.specificArea.name);
    setValue("code_detailed", item.row.original.code);
    setValue("name_detailed", item.row.original.label);
  }, [item]);

  const code_general = watch("code_general");
  const name_general = watch("name_general");

  const code_specific = watch("code_specific");
  const name_specific = watch("name_specific");

  const code_detailed = watch("code_detailed");
  const name_detailed = watch("name_detailed");

  const { table } = useParams();

  const onSubmit = async (data) => {
    setLoading(true);

    const entity_name = lookUps[table - 1].entityName;

    try {
      const bodyRequest = {
        Code: data.code_detailed,
        Name: data.name_detailed,
        EntityType: entity_name,
        ParentId: item.row.original.specificArea.id,
      };

      await api.patch(UPDATE_ITEM_LOOKUP, {
        ...bodyRequest,
        id: item.row.original.value,
      });

      addToast({
        status: "success",
        title: "Item actualizado com sucesso",
      });

      const response = await lookUps[table - 1].getFunction({
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(response);
      onClose();
    } catch (e) {
      addToast({
        status: "error",
        title: "Ocorreu um erro ao actualizar o item",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <EditIcon
        onClick={() => {
          onOpen();
        }}
      />
      <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Actualizar item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={5}>
                <Box>
                  <Text mb="5" fontWeight="bold">
                    Área de Formação CITE (ISCED) 2013 - Geral
                  </Text>

                  <HStack gap={5}>
                    <FormControl isInvalid={!!errors.code_general}>
                      <Label title="Código" isRequired />
                      <Input
                        value={code_general}
                        {...register("code_general")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.code_general && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name_general}>
                      <Label title="Nome" isRequired />
                      <Input
                        value={name_general}
                        {...register("name_general")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.name_general && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>

                <>
                  <Box>
                    <Text mb="5" fontWeight="bold">
                      Área de Formação CITE (ISCED) 2013 - Específica
                    </Text>

                    <HStack gap={5}>
                      <FormControl isInvalid={!!errors.code_specific}>
                        <Label title="Código" isRequired />
                        <Input
                          value={code_specific}
                          {...register("code_specific")}
                          size="md"
                          disabled
                        />
                        <FormErrorMessage>
                          {errors.code_specific && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.name_specific}>
                        <Label title="Nome" isRequired />
                        <Input
                          value={name_specific}
                          {...register("name_specific")}
                          size="md"
                          disabled
                        />
                        <FormErrorMessage>
                          {errors.name_specific && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </Box>
                </>

                <>
                  <Box>
                    <Text mb="5" fontWeight="bold">
                      Área de Formação CITE (ISCED) 2013 - Detalhada
                    </Text>

                    <HStack gap={5}>
                      <FormControl isInvalid={!!errors.code_detailed}>
                        <Label title="Código" isRequired />
                        <Input
                          value={code_detailed}
                          {...register("code_detailed")}
                          size="md"
                        />
                        <FormErrorMessage>
                          {errors.code_detailed && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.name_detailed}>
                        <Label title="Nome" isRequired />
                        <Input
                          value={name_detailed}
                          {...register("name_detailed")}
                          size="md"
                        />
                        <FormErrorMessage>
                          {errors.name_detailed && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </Box>
                </>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button type="submit" isLoading={loading} isDisabled={loading}>
                Actualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

import { Heading, Text, Stack, Box, Flex, Button } from "@chakra-ui/react";

import Tableconfig from "./TableConfig";

import { useForm } from "react-hook-form";
import { SearchConfig } from "./components/SearchConfig";
import handleFilterOrSearch from "../../helpers/handleFilterOrSearch";
import { Toast } from "../../components/Toast";
import { useContext, useState } from "react";

import { getSubject } from "../../services/getSubject";
import { LOOKUP_SUMMARY } from "../../utils/constants";
import { getGender } from "../../services/getGender";
import { getAcademicQualification } from "../../services/getAcademicQualification";
import { getNationality } from "../../services/getNationality";
import { getCommune } from "../../services/getCommune";

import { getSpecialNeedsType } from "../../services/getSpecialNeedsType";
import { getContratualBond } from "../../services/getContratualBond";
import { getClassModality } from "../../services/getClassModality";
import { getDomainPNFQ } from "../../services/getDomainPNFQ";
import { getAdministrativePosition } from "../../services/getAdministrativePosition";
import { getPedagogicalTraining } from "../../services/getPedagogicalTraining";
import { getTrainingArea } from "../../services/getTrainingArea";
import { getClassShift } from "../../services/getClassShift";
import { getSchoolTipology } from "../../services/getSchoolTipology";
import { getSchoolType } from "../../services/getSchoolTypes";
import { getDetailedArea } from "../../services/getDetailedArea";
import { getTechinicalEducation } from "../../services/getTechinicalEducation";
import { getSchoolNatures } from "../../services/getSchoolNatures";
import { AuthContext } from "../../provider/AuthProvider";
import { formStyles } from "../../utils/styles";
import { Link, useParams } from "react-router-dom";
import { getIdentificationDocumentType } from "../../services/getIdentificationDocumentType";
import { getLabType } from "../../services/getLabType";
import { MdArrowOutward } from "react-icons/md";

export const lookUps = [
  {
    name: "Área de Formação (Professores)",
    id: 1,
    entityName: "TrainingArea",
    getFunction: getTrainingArea,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Área de Formação CITE (ISCED) 2013 - Geral / Específica / Detalhada",
    id: 2,
    entityName: "CourseDetailedArea",
    getFunction: getDetailedArea,
    isStructural: true,
    hasMultiparent: true,
    hasProvince: false,
  },
  {
    name: "Área de Formação do INFQE",
    id: 3,
    entityName: "CourseInfqeArea",
    getFunction: getTechinicalEducation,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Domínios PNFQ",
    id: 4,
    entityName: "CoursePNFQ",
    getFunction: getDomainPNFQ,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Cargo não lectivo",
    id: 5,
    entityName: "AdministrativePosition",
    getFunction: getAdministrativePosition,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Disciplina",
    id: 6,
    entityName: "Subject",
    getFunction: getSubject,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Formação Pedagógica",
    id: 7,
    entityName: "PedagogicalTraining",
    getFunction: getPedagogicalTraining,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Género",
    id: 8,
    entityName: "Gender",
    getFunction: getGender,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Habilitação Académica (Professores)",
    id: 9,
    entityName: "AcademicQualification",
    getFunction: getAcademicQualification,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Modalidade",
    id: 10,
    entityName: "ClassModality",
    getFunction: getClassModality,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Turno",
    id: 11,
    entityName: "ClassShift",
    getFunction: getClassShift,
    getParent: getClassModality,
    ParentName: "Modalidade",
    isStructural: true,
  },
  {
    name: "Nacionalidade",
    id: 12,
    entityName: "Nationality",
    getFunction: getNationality,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Natureza",
    id: 13,
    entityName: "SchoolNature",
    getFunction: getSchoolNatures,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Província / Município / Comuna / Distrito Urbano",
    id: 14,
    entityName: "Province",
    getFunction: getCommune,
    isStructural: true,
    hasMultiparent: true,
    hasProvince: true,
  },
  {
    name: "Tipo de documento de identificação",
    id: 15,
    entityName: "IdentificationDocumentType",
    getFunction: getIdentificationDocumentType,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Tipologia de Escola",
    id: 16,
    entityName: "SchoolTypology",
    getFunction: getSchoolTipology,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Ramo da Escola",
    id: 17,
    entityName: "SchoolType",
    getFunction: getSchoolType,
    getParent: undefined,
    isStructural: true,
  },
  {
    name: "Tipo de NEE",
    id: 18,
    entityName: "SpecialEducationNeedType",
    getFunction: getSpecialNeedsType,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Vínculo",
    id: 19,
    entityName: "ContractualBondType",
    getFunction: getContratualBond,
    getParent: undefined,
    isStructural: false,
  },
  {
    name: "Infra-estrutura",
    id: 20,
    entityName: "LabType",
    getFunction: getLabType,
    getParent: undefined,
    isStructural: false,
  },
];

function Schools() {
  const { addToast } = Toast();
  const { register, watch, setValue } = useForm();
  const province = watch("province");
  const county = watch("county");
  const natures = watch("natures");
  const type = watch("type");
  const inputSearch = watch("search");
  const { auth } = useContext(AuthContext);
  const { table } = useParams();
  const paramsObj = {
    SchoolName: inputSearch,
    ProvinceId: province?.value,
    CountyId: county?.value,
    SchoolNatureId: natures?.value,
    SchoolTypologyId: type?.value,
  };
  const user = auth.user;
  const permissionNameStructual = "ManageLookups";
  const permissionNameNonStructual = "ManageLookupsNational";
  const [dataTable, setDataTable] = useState(
    lookUps.filter(
      (e) =>
        (e.isStructural &&
          user.permissions.includes(permissionNameStructual)) ||
        (!e.isStructural &&
          user.permissions.includes(permissionNameNonStructual))
    )
  );

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Configurações</Heading>
        <Text width="sm">{LOOKUP_SUMMARY}</Text>
      </Box>

      <Box sx={formStyles} px="0!important">
        <SearchConfig
          params={paramsObj}
          handleFilterOrSearchCourse={handleFilterOrSearch}
          register={register}
          addToast={addToast}
          setDataTable={setDataTable}
          setInputSearch={setValue}
          inputSearch={inputSearch}
          dataTable={dataTable}
          lookUps={lookUps}
          table={table}
        />
      </Box>

      <Box>
        <Tableconfig setDataTable={setDataTable} dataTable={dataTable} />

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-administracao-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
    </Stack>
  );
}

export default Schools;

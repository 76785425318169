import { Box, Button, Flex, FormControl, Grid, Input } from "@chakra-ui/react";
import Label from "../../../components/Label";
import { handleEnterOnSearch } from "../../../utils/constants";

function Search({ inputValue, setInputValue, handleSearch }) {
  const clearSearch = () => {
    setInputValue("");
    handleSearch();
  };

  return (
    <Box px="20px">
      <FormControl>
        <Label title="Pesquisar pela designação da escola" />
        <Grid gridTemplateColumns="1fr auto">
          <Flex mr={4}>
            <Input
              value={inputValue}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onKeyDown={(e) => {
                handleEnterOnSearch(e, handleSearch);
              }}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <Button
              onClick={handleSearch}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              Pesquisar
            </Button>
          </Flex>
          <Flex>
            <Button variant="link" onClick={clearSearch}>
              Limpar consulta
            </Button>
          </Flex>
        </Grid>
      </FormControl>
    </Box>
  );
}

export default Search;

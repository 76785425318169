import { useMemo, useState } from "react";
import Table from "../../../components/Table";
import { Button, Spinner } from "@chakra-ui/react";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import DownloadReport from "./DownloadReport";

function TableStudents({
  user,
  data,
  academicYear,
  province,
  county,
  school,
  provinceId,
  countyId,
  schoolId,
  academicYearOptions,
}) {
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Indicador",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Tabela base",
        disableSortBy: true,
        Cell: (item) => {
          const [loading, setLoading] = useState(false);
          return (
            <Button
              variant="secondary"
              isDisabled={loading}
              onClick={() => {
                const id =
                  item.row.original.id === 1 || item.row.original.id === 2
                    ? 4
                    : item.row.original.id;
                let apiUrl = `/api/Reports/downloadStudentsReport?ReportType=${id}`;
                apiUrl += academicYear
                  ? `&AcademicYearId=${academicYear?.value}`
                  : "";
                apiUrl +=
                  province || provinceId
                    ? `&ProvinceId=${province?.value || provinceId}`
                    : "";
                apiUrl +=
                  county || countyId
                    ? `&CountyId=${county?.value || countyId}`
                    : "";
                apiUrl +=
                  school || schoolId
                    ? `&SchoolId=${school?.value || schoolId}`
                    : "";
                downloadExcelFile(
                  apiUrl,
                  `${item.row.original.name}.xlsx`,
                  setLoading
                );
              }}
            >
              {loading ? <Spinner size="sm" /> : "Descarregar"}
            </Button>
          );
        },
      },
    ];

    if (user.roleLevel >= 800) {
      baseColumns.push({
        Header: "Quadros padronizados",
        accessor: "standardBoard",
        disableSortBy: true,
        Cell: (item) => {
          let apiUrl = `/api/Reports/downloadStudentsChartTablesReport?ReportType=`;
          return (
            <DownloadReport
              item={item}
              apiUrl={apiUrl}
              academicYearOptions={academicYearOptions}
            />
          );
        },
      });
    }

    return baseColumns;
  }, [academicYear, province, county, school, academicYearOptions]);

  return (
    <>{data && <Table columns={columns} data={data} isPaginated={false} />}</>
  );
}

export default TableStudents;
